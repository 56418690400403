import api from '../plugins/api.js'

export default {
    async headerAutocompleteSearch(payload= {}) {
        const response = await api().get(`${process.env.VUE_APP_BASE_URL}/v1/search/`, {
            params: payload
        });

        return response.data.data;
    },

	async autocompleteSearch(payload= {}) {
		const response = await api().get(`${process.env.VUE_APP_BASE_URL}/v1/multisearch/`, {
			params: payload
		});

		return response.data.data;
	},

    // With pagination
    async index( payload= {}, page = '') {
        const response = await api().get(`${process.env.VUE_APP_BASE_URL}/v1/search${page}`, {
            params: payload
        });

        return response.data;
    },

    async clientAutocompleteSearch(payload= {}) {
        const response = await api().get(`${process.env.VUE_APP_BASE_URL}/v1/search-client/`, {
            params: payload
        });

        return response.data.data;
    },

    async serialAutocompleteSearch(payload= {}) {
        const response = await api().get(`${process.env.VUE_APP_BASE_URL}/v1/search-serial/`, {
            params: payload
        });

        return response.data.data;
    },

    async supplierAutocompleteSearch(payload= {}) {
        const response = await api().get(`${process.env.VUE_APP_BASE_URL}/v1/search-supplier/`, {
            params: payload
        });

        return response.data.data;
    },

    async copyFromAutocompleteSearch(payload = {}) {
        const response = await api().get(`${process.env.VUE_APP_BASE_URL}/v1/search-copy-from/`, {
            params: payload
        });

        return response.data.data;
    },

    async getProductsFrom(payload ={}) {
        const response = await api().get(`${process.env.VUE_APP_BASE_URL}/v1/get-products-from/`, {
            params: payload
        });

        return response.data.data;
    }
}
