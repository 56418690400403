export default [
    {
        path: '/quote:subscriber_id?',
        name: 'quote.index',
        component: () => import(/* webpackChunkName: "quote" */ '../views/Quote/Quote.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        },
    },
    {
        path: '/quote/:id/edit',
        name: 'quote.edit',
        component: () => import(/* webpackChunkName: "editQuote" */ '../views/Quote/EditQuote.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        },
    },
    {
        path: '/quotePDF/:id',
        name: 'QuotePDF',
        component: () => import(/* webpackChunkName: "quotePDF" */ '../views/Quote/QuotePDF.vue'),
        meta: {
            auth: true,
            layout: "empty"
        }
    },
    {
        path: '/my-quotes',
        name: 'MyQuotes',
        component: () => import(/* webpackChunkName: "myQuotes" */ '../views/Quote/MyQuotes.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['client'],
        }
    },
    {
        path: '/view-quote/:id',
        name: 'ViewQuote',
        component: () => import(/* webpackChunkName: "viewQuote" */ '../views/Quote/ViewQuote.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['client'],
        },
    },
]
