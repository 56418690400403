export default [
	{
		path: '/receivable',
		name: 'receivable.index',
		component: () => import(/* webpackChunkName: "receivale" */ '../views/Receivable/Receivable.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'],
		},
	},
	{
		path: '/receivable-payment',
		name: 'receivablePayment.index',
		component: () => import(/* webpackChunkName: "receivalePayment" */ '../views/Receivable/ReceivablePayment.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'],
		},
	},
	{
		path: '/receivable-payment/:id/view',
		name: 'receivablePayment.view',
		component: () => import(/* webpackChunkName: "viewReceivalePayment" */ '../views/Receivable/ViewReceivablePayment.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'],
		},
	},
	{
		path: '/payment-refund',
		name: 'paymentRefund.index',
		component: () => import(/* webpackChunkName: "paymentRefund" */ '../views/Receivable/PaymentRefund.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/add-payment-refund',
		name: 'paymentRefund.store',
		component: () => import(/* webpackChunkName: "addPaymentRefund" */ '../views/Receivable/AddPaymentRefund.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/payment-refund/:id/view',
		name: 'paymentRefund.view',
		component: () => import(/* webpackChunkName: "viewPaymentRefund" */ '../views/Receivable/ViewPaymentRefund.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
]
