<template>
    <div>
        <div class="my-2 py-2 bg-navy-blue text-center">
            <template v-if="!collapseSidebar">
                <img alt="oneApp logo" src="../../assets/img/logo_oneapp_white.svg" class="block logo-white mx-auto">
                <locale-selector />
            </template>
        </div>
    </div>
</template>
<script>
    import LocaleSelector from './LocaleSelector.vue';
    export default {
        name: 'SideBarFooter',

        components: { LocaleSelector },

        props: {
            collapseSidebar: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>

<style>
.logo-white {
    padding-top: 5px;
    width: 173px;
}
</style>
