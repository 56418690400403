export default [
    {
        path: '/task-type',
        name: 'taskType.index',
        component: () => import(/* webpackChunkName: "taskType" */ '../views/TaskType/TaskType.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration'],
        },
    },
    {
        path: '/add-task-type',
        name: 'taskType.store',
        component: () => import(/* webpackChunkName: "addTaskType" */ '../views/TaskType/AddTaskType.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration'],
        }
    },
    {
        path: '/task-type/:id/edit',
        name: 'taskType.edit',
        component: () => import(/* webpackChunkName: "editTaskType" */ '../views/TaskType/EditTaskType.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration'],
        }
    },
    {
        path: '/task-setting',
        name: 'taskSetting.index',
        component: () => import(/* webpackChunkName: "task" */ '../views/TaskType/TaskSetting.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        },
    },
]
