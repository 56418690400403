<template>
    <div>
        <div class="w-full bg-navy-blue text-white ">
            <div class="flex flex-wrap">
                <div class="sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/6 text-center my-4">

                </div>
                <div class="sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/6 ">
                    <!--column 2-->
                </div>
                <div class="sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/6 ">
                    <!--column 3-->
                </div>
                <div class="sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/6 ">
                    <!--column 4-->
                </div>
                <div class="sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/6 ">
                    <!--column 5-->
                </div>
                <div class="sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/6 my-4 sm:text-center md:text-left footer-link">
                    <h4 class="mb-2 font-semibold">HOW CAN WE HELP</h4>
                    <div class="my-1"><a href="#" class="text-white text-sm">Help / FAQ</a></div>
                    <div class="my-1"><a href="https://oneapp.work/docs" class="text-white text-sm">Documentation</a></div>
                    <div class="my-1"><a href="#" class="text-white text-sm">Website support</a></div>
                    <div class="my-1"><a href="#" class="text-white text-sm">Create a ticket</a></div>
                    <div class="my-1"><a href="#" class="text-white text-sm">Feedback</a></div>

                    <h4 class="mt-4 mb-2 font-semibold">WHO WE ARE</h4>
                    <div class="my-1"><a href="#" class="text-white text-sm">About us</a></div>
                    <div class="my-1"><a href="#" class="text-white text-sm">Contact us</a></div>
                    <div class="my-1"><a href="#" class="text-white text-sm">Careers</a></div>
<!--					<span id="siteseal"><script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=LtIw6drOwZBfko2ig4wk1SkfG50Vke6MGZyaR4c8cxUDaWnXLitctIuC46bW"></script></span>-->
                </div>
            </div>
        </div>
        <div class="w-full text-center text-xs">© 2019 Lap Houng Nghia. All Rights Reserved.</div>
    </div>
</template>
<script>
    export default {
        name: 'MainFooter'
    }
</script>
