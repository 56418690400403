export default [
    {
        path: '/staff',
        name: 'staff.index',
        component: () => import(/* webpackChunkName: "staff" */ '../views/Staff/Staff.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration'],
        },
    },
    {
        path: '/add-staff',
        name: 'staff.store',
        component: () => import(/* webpackChunkName: "addStaff" */ '../views/Staff/AddStaff.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration'],
        },
    },
    {
        path: '/staff/:id/edit',
        name: 'staff.edit',
        component: () => import(/* webpackChunkName: "editStaff" */ '../views/Staff/EditStaff.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration'],
        }
    }
]
