export default {
	createQuote(state, quoteMode) {
		state.createQuote = quoteMode;
	},

	createOrderFromCart(state, orderMode) {
		state.createOrderFromCart = orderMode;
	},

	createOrderFromQuote(state, orderMode) {
		state.createOrderFromQuote = orderMode;
	},

	createOrderFromQuoteId(state, id) {
		state.createOrderFromQuoteId = id;
	},

	createInvoiceFromCart(state, orderMode) {
		state.createInvoiceFromCart = orderMode;
	},

	createInvoiceFromOrder(state, orderMode) {
		state.createInvoiceFromOrder = orderMode;
	},

	createInvoiceFromOrderId(state, id) {
		state.createInvoiceFromOrderId = id;
	},

	setCurrentView(state, payload) {
		state.collapseSidebar = payload;
	}
}
