<template>
    <button
		:disabled="disabled"
		:class="computedClass"
        :type="submit ? 'submit' : 'button'"
        v-on="$attrs"
    >
		<el-icon class="is-loading mr-2" v-show="loading">
			<loading />
		</el-icon>
		<slot/>
    </button>
</template>

<script>
import { Loading } from '@element-plus/icons-vue';

export default {
        name: 'AppButton',

		components: {
			Loading
		},

        props: {
            submit: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false,
            },
			disabled: {
				type: Boolean,
				default: false,
			},
			class: {
				type: String,
				default: ''
			}
        },

		computed: {
			computedClass() {
				return this.class;
			}
		}
    }
</script>
