import current from '@/api/current.js';
import defaultParams from '@/api/defaultParams.js';

export default {
    async loadUserSite({commit}, userId) {
        const response = await current.loadUserSite(userId);

        commit('setCurrent', response);
        return response;
    },

    async loadDefaultParams({commit}, subscriberId) {
        const response = await defaultParams.index(subscriberId);

        commit('setDefaultParams', response);
        return response;
    },

    async loadCacheScheduleUsers({commit}, payload) {
        const response = await defaultParams.loadCacheScheduleUsers(payload);

        commit('setScheduleUsers', response);
        return response;
    },

    async updateCacheScheduleUsers({commit}, payload) {
        const response = await defaultParams.updateCacheScheduleUsers(payload);

        commit('setScheduleUsers', response);
        return response;
    }
}
