<template>
<div>
    <nav>
        <div class="top-0 left-0 mx-auto">
            <div class="flex items-center justify-between h-56px">
                <div class="flex-none items-center">
                    <div class="bg-white w-260px h-56px flex justify-around items-center flex-shrink-0">
						<router-link
							to="/home"
						>
							<img class="block logo ml-2" alt="oneApp logo" src="../../assets/img/logo_oneapp.svg" >
						</router-link>
                        <button @click="toggleSidebar">
                            <span v-if="collapseSidebar" class="text-sapphire ml-0 px-3 py-3"><i class="fal fa-list-ul" /></span>
                            <span v-else class="text-sapphire ml-1 px-4 py-3"><i class="fal fa-ellipsis-v" /></span>
                        </button>
                    </div>


<!--                    <div class="hidden md:block bg-navy-blue">-->
<!--                        <div class=" ml-10 flex items-baseline space-x-4">-->
<!--                            <a href="#" class="px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700">Dashboard</a>-->
<!--                            <a href="#" class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">Team</a>-->
<!--                            <a href="#" class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">Projects</a>-->
<!--                            <a href="#" class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">Calendar</a>-->
<!--                            <a href="#" class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">Reports</a>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
                <div class="flex-grow justify-around items-center">
                    <div class="hidden md:block bg-navy-blue w-full h-56px place-self-center">
                        <search-bar-header />
                    </div>
                </div>

                <div class="hidden md:block flex-none">
                    <div class="bg-navy-blue flex items-center h-56px">
                        <router-link
                            to="/my-agenda"
                            class="block mt-4 md:inline-block md:mt-0 ml-8 mr-4 text-white font-semibold cursor-pointer text-left " v-if="subscriberAccess"
                        >
                            <i class="fal fa-calendar-alt text-lg" />
                        </router-link>
                        <a class="block mt-4 md:inline-block md:mt-0 ml-4 mr-4 text-white font-semibold cursor-pointer">
                            <i class="fal fa-bell text-lg" />
                        </a>
                        <a @click="showFavorite = true" class="block mt-4 md:inline-block md:mt-0 ml-4 mr-4 text-white font-semibold cursor-pointer">
                            <el-badge :value="favoriteCounter" :max="99" class="oneapp-favorite">
                                <i class="fal fa-heart text-lg" />
                            </el-badge>
                        </a>
                        <a @click="showCart = true" class="block mt-4 md:inline-block md:mt-0 ml-4 mr-8 text-white font-semibold cursor-pointer">
                            <el-badge :value="cartCounter" :max="99" class="oneapp-cart">
                                <i class="fal fa-shopping-cart text-lg" />
                            </el-badge>
                        </a>
                        <span @click="toggleHeaderUserDropDown" class="block md:inline-block md:mt-0 text-white sm:hidden cursor-pointer ">
<!--                            <span class="initial truncate">LHN</span>-->
                            <img
                                alt="profile picture"
                                class="rounded-full align-middle profile-header-thmb"
                                :src="image_src + getAuthUser.profilePicture"
                                v-if="getAuthUser.profilePicture" >
                            <span v-else class="initial">{{nameInitial}}</span>
                        </span>
                        <div @click="toggleHeaderUserDropDown" class="block mt-4 md:inline-block md:mt-0 text-white ml-2 mr-4 sm:hidden xs:hidden cursor-pointer flex items-center">
                            <span class="font-semibold">
                                 {{user.firstName}} {{user.lastName}} <i class="far fa-chevron-down fa-inverse" v-if="headerDropdownShow === false"/> <i class="far fa-chevron-up fa-inverse" v-else/>
                            </span>
                        </div>
                    </div>
                </div>
                <!-- Mobile - burger button-->
                <div class="mr-4 flex md:hidden">
                    <!-- Mobile menu button -->
                    <button class="flex items-center px-3 py-2 border rounded text-navy-blue border-navy-blue hover:text-navy-blue-darker  hover:text-navy-blue-400 " @click="toggleMenuBurgerHeader">
                        <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
                    </button>
                </div>
            </div>
        </div>

        <!-- Large screen - Header right dropdown menu - toggle with  md:block or '' class-->
        <div
            class="xs:hidden dropdown fixed z-40 top-0 right-0 bg-white rounded border border-gray sm:hidden"
            id="user-menu-right"
            :class="headerDropdownShow ? 'md:block': ''"
            @mouseleave="mouseLeave"
        >
            <ul class="py-1">
                <li>
                    <router-link
                        class="px-4 block text-sapphire text-left"
                        to="/profile"
                    >
                        <i class="far fa-user-circle pr-2 text-lg" />
                        <span>{{$t('headerNav.myProfile')}}</span>
                    </router-link>
                </li>
                <!--<li><a href="#" class="px-4 block text-black hover:bg-grey-light"><i class="far fa-user-circle text-blue-dark pr-2 text-lg"></i>My profile </a></li>-->
                <template v-if="!subscriberAccess">
                    <hr class="border-t border-gray-400 m-0">
                    <li>
                        <router-link
                            class="px-4 block text-sapphire text-left"
                            to="/my-quotes"
                        >
                            <i class="fal fa-pen-alt pr-3 text-apple"/>
                            <span>{{$t('headerNav.myQuotes')}}</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="px-4 block text-sapphire text-left"
                            to="/my-orders"
                        >
                            <i class="fal fa-box-open pr-3 text-pure-orange" />
                            <span>{{$t('headerNav.myOrders')}}</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="px-4 block text-sapphire text-left"
                            to="/my-invoices"
                        >
                            <i class="fal fa-cash-register pr-3 text-fire-brick" />
                            <span>{{$t('headerNav.myInvoices')}}</span>
                        </router-link>
                    </li>
					<li>
						<router-link
							class="px-4 block text-sapphire py-4 text-left"
							to="/my-tickets"
						>
							<i class="fal fa-ticket-alt pr-2 text-tangerine" />
							<span>{{$t('headerNav.myTickets')}}</span>
						</router-link>
					</li>
                </template>
                <hr class="border-t border-gray-400 m-0">
                <li><a href="#" @click.prevent="signOut" class="px-4 block text-sapphire text-left"><i class="far fa-sign-out-alt pr-2 text-lg"></i><span>{{$t('headerNav.logout')}}</span></a></li>
            </ul>
        </div>

        <!-- Mobile - drop down menu -->
        <div :class="{hidden : menuBurgerOpen}" class="md:hidden" @mouseleave="mouseLeaveMobileDropdown" id="user-menu-right-mobile">
            <ul class="py-1">
                <li>
                    <router-link
                        class="px-5 block text-sapphire py-4 text-left"
                        to="/profile"
                    >
                        <i class="fal fa-calendar-alt pr-2" />
                        <span>{{$t('headerNav.calendar')}}</span>
                    </router-link>
                </li>
                <li>
                    <router-link
                        class="px-5 block text-sapphire py-4 text-left"
                        to="/profile"
                    >
                        <i class="far fa-bell pr-2" />
                        <span>{{$t('headerNav.notifications')}}</span>
                    </router-link>
                </li>
                <li>
                    <router-link
                        class="px-5 block text-sapphire py-4 text-left"
                        to="/profile"
                    >
                        <i class="far fa-heart pr-2 text-deep-cerise" />
                        <span>{{$t('headerNav.favorites')}}</span>
                    </router-link>
                </li>
                <li>
                    <router-link
                        class="px-5 block text-sapphire py-4 text-left"
                        to="/profile"
                    >
                        <i class="far fa-shopping-cart pr-2 text-apple" />
                        <span>{{$t('headerNav.cart')}}</span>
                    </router-link>
                </li>
                <li>
                    <router-link
                        class="px-5 block text-sapphire py-4 text-left"
                        to="/profile"
                    >
                        <i class="far fa-user-circle pr-2 " />
                        <span>{{$t('headerNav.myProfile')}}</span>
                    </router-link>
                </li>
                <template v-if="!subscriberAccess">
                    <li>
                        <router-link
                            class="px-5 block text-sapphire py-4 text-left"
                            to="/my-quotes"
                        >
                            <i class="fal fa-pen-alt pr-2 text-apple"/>
                            <span>{{$t('headerNav.myQuotes')}}</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="px-5 block text-sapphire py-4 text-left"
                            to="/my-orders"
                        >
                            <i class="fal fa-box-open pr-2 text-pure-orange" />
                            <span>{{$t('headerNav.myOrders')}}</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="px-5 block text-sapphire py-4 text-left"
                            to="/my-invoices"
                        >
                            <i class="fal fa-cash-register pr-2 text-fire-brick" />
                            <span>{{$t('headerNav.myInvoices')}}</span>
                        </router-link>
                    </li>
					<li>
						<router-link
							class="px-5 block text-sapphire py-4 text-left"
							to="/my-tickets"
						>
							<i class="fal fa-ticket-alt pr-2 text-tangerine" />
							<span>{{$t('headerNav.myTickets')}}</span>
						</router-link>
					</li>
                </template>
                <li>
                    <a href="#" @click.prevent="signOut" class="px-5 block text-sapphire text-left py-4"><i class="far fa-sign-out-alt pr-2"></i> {{$t('headerNav.logout')}}</a>
                </li>
            </ul>
        </div>
    </nav>
	<favorite-list :favorite-visible="showFavorite" @close="showFavorite = false"/>
	<cart-list :cart-visible="showCart" @close="showCart = false"/>
</div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import SearchBarHeader from '../forms/SearchBarHeader.vue';
    import { strInitial } from '@/utils';
	import { defineAsyncComponent } from 'vue';
	import AppLoading from '@/elements/AppLoading.vue';

	const FavoriteList = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: 'FavoriteList' */'../../components/modals/FavoriteList.vue'),
		loadingComponent: AppLoading
	})

	const CartList = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: 'CartList' */'../../components/modals/CartList.vue'),
		loadingComponent: AppLoading
	})

    export default {
        name: 'HeaderNav',

        components: {
            SearchBarHeader,
			FavoriteList,
			CartList,
        },

        data() {
            return {
                menuBurgerOpen: true,
                headerDropdownShow: false,
                collapseSidebar: false,
                user: {},
                image_src : process.env.VUE_APP_URL_PROFILES,
				cartCounter: '',
				showCart: false,
				favoriteCounter: '',
				showFavorite: false,
            };
        },

        computed: {
            ...mapGetters([
                'getAuthUser',
            ]),

			subscriberAccess() {
				const roleTypes = ['administration', 'subscriber'];
				return roleTypes.includes(this.getAuthUser.role.type);
			},

			nameInitial() {
                return strInitial(`${this.getAuthUser.firstName} ${this.getAuthUser.lastName}`);
            }
        },

        emits: ['update:collapseSidebar'],

        methods: {
            ...mapActions([
                'logout'
            ]),

            toggleMenuBurgerHeader() {
                this.menuBurgerOpen = !this.menuBurgerOpen;
            },

            toggleHeaderUserDropDown() {
                this.headerDropdownShow = !this.headerDropdownShow;
            },

            toggleSidebar() {
                this.collapseSidebar = !this.collapseSidebar;
                this.$emit('update:collapseSidebar', this.collapseSidebar);
                this.$store.commit('setCurrentView', this.collapseSidebar);
            },

            mouseLeave() {
                this.headerDropdownShow = false;
            },

            mouseLeaveMobileDropdown() {
                this.menuBurgerOpen = !this.menuBurgerOpen;
            },

            async signOut() {
                await this.logout();
                this.$router.replace({ name: 'Login' });
            }
        },

        created() {
            this.user = this.getAuthUser;
			this.emitter.on('favorite-counter', favoriteCounter => {
				this.favoriteCounter = favoriteCounter;
			})
            this.emitter.on('cart-counter', cartCounter => {
            	this.cartCounter = cartCounter;
			});
        },

		unmounted() {
			this.emitter.off('favorite-counter', () => {
				this.favoriteCounter = '';
			});
        	this.emitter.off('cart-counter', () => {
        		this.cartCounter = '';
			});
		}
	}
</script>

<style>
.logo {
    width: 170px;
}
.initial {
    border-radius: 3px 10px 3px;
    padding: 4px 6px;
    background: #4a5568;
}

#menu {
    min-height: 48px;
}
/* Header - right dropdown menu */
#user-menu-right {
    margin-top: 56px;
    width: 220px;
    font-size: 16px;
    /*font-weight: 600;*/
    margin-right: 8px;
}
#user-menu-right li :hover {
    background-color: #e6eeff;
}

#user-menu-right-mobile {
    min-width: 180px;
    font-size: 16px;
    /*font-weight: 600;*/
}
#user-menu-right-mobile li :hover {
    background-color: #e6eeff;
}

.dropdown li a {
    padding-top: 12px;
    padding-bottom: 12px;
    vertical-align: middle;
}
.dropdown i {
    vertical-align: middle;
}

.text-sapphire span {
    position: absolute;
    left: 48px;
}
/* End header */
</style>
