export default [
    {
        path: '/contract',
        name: 'contract.index',
        component: () => import(/* webpackChunkName: "contract" */ '../views/Contract/Contract.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        },
    },
    {
        path: '/contract/:id/edit',
        name: 'contract.edit',
        component: () => import(/* webpackChunkName: "editContract" */ '../views/Contract/EditContract.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        }
    },
	{
		path: '/add-serial-to-contract',
		name: 'serialContract.store',
		component: () => import(/* webpackChunkName: "addSerialToContract" */ '../views/Contract/AddSerialToContract.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/contract-tc',
		name: 'contractTC.index',
		component: () => import(/* webpackChunkName: "contractTC" */ '../views/Contract/ContractTC.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive'],
		},
	},
	{
		path: '/service-contract',
		name: 'serviceContract.index',
		component: () => import(/* webpackChunkName: "serviceContract" */ '../views/Contract/ServiceContract.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/add-service-contract',
		name: 'serviceContract.store',
		component: () => import(/* webpackChunkName: "addServiceContract" */ '../views/Contract/AddServiceContract.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/service-contract/:id/edit',
		name: 'serviceContract.edit',
		component: () => import(/* webpackChunkName: "editServiceContract" */ '../views/Contract/EditServiceContract.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		}
	},
	{
		path: '/service-contract-tc',
		name: 'serviceContractTC.index',
		component: () => import(/* webpackChunkName: "serviceContractTC" */ '../views/Contract/ServiceContractTC.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive'],
		},
	},
	{
		path: '/contract-discount',
		name: 'contractDiscount.index',
		component: () => import(/* webpackChunkName: "contractDiscount" */ '../views/Contract/ContractDiscount.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive'],
		},
	},
	{
		path: '/previewContractInvoicePDF',
		name: 'PreviewContractInvoicePDF',
		component: () => import(/* webpackChunkName: "previewContractInvoicePDF" */ '../views/Contract/PreviewContractInvoicePDF.vue'),
		meta: {
			auth: true,
			layout: "empty"
		}
	},
	{
		path: '/contractInvoicePDF',
		name: 'ContractInvoicePDF',
		component: () => import(/* webpackChunkName: "contractInvoicePDF" */ '../views/Contract/ContractInvoicePDF.vue'),
		meta: {
			auth: true,
			layout: "empty"
		}
	},
	{
		path: '/contractPDF/:id',
		name: 'ContractPDF',
		component: () => import(/* webpackChunkName: "contractPDF" */ '../views/Contract/ContractPDF.vue'),
		meta: {
			auth: true,
			layout: "empty"
		}
	},
	{
		path: '/serviceContractPDF/:id',
		name: 'ServiceContractPDF',
		component: () => import(/* webpackChunkName: "serviceContractPDF" */ '../views/Contract/ServiceContractPDF.vue'),
		meta: {
			auth: true,
			layout: "empty"
		}
	},
	{
		path: '/invoice-processing',
		name: 'invoiceProcessing.index',
		component: () => import(/* webpackChunkName: "contract" */ '../views/Contract/InvoiceProcessing.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
]
