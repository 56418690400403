import api from '../plugins/api.js'

export default {
    // Get all configs
    async index(payload = {}) {
        const response = await api().get(`${process.env.VUE_APP_BASE_URL}/v1/configs`, payload);

        return response.data.data;
    },

    // Get sale tax from userId (which in turn get its from siteId where it collects the specified taxes
    async loadSiteTaxes(userId) {
        const response = await api().get(`${process.env.VUE_APP_BASE_URL}/v1/configs-site-taxes/${userId}`);

        return response.data.data;
    },

	// Get sale tax from current order or invoice (which is created originally)
	async loadCurrentSiteTaxes(siteId) {
		const response = await api().get(`${process.env.VUE_APP_BASE_URL}/v1/configs-current-site-taxes/${siteId}`);

		return response.data.data;
	},

    // Get client taxes
    async loadClientTaxes(clientId) {
        const response = await api().get(`${process.env.VUE_APP_BASE_URL}/v1/configs-client-taxes/${clientId}`);

        return response.data.data;
    }
}

