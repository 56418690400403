export default [
    {
        path: '/custom-field',
        name: 'customField.index',
        component: () => import(/* webpackChunkName: "customField" */ '../views/CustomField/CustomField.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        },
    },
    {
        path: '/add-custom-field',
        name: 'customField.store',
        component: () => import(/* webpackChunkName: "addCustomField" */ '../views/CustomField/AddCustomField.vue'),
        meta: {
			layout: "default",
            auth: true,
            roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive'],
        }
    },
    {
        path: '/custom-field/:id/edit',
        name: 'customField.edit',
        component: () => import(/* webpackChunkName: "editCustomField" */ '../views/CustomField/EditCustomField.vue'),
        meta: {
			layout: "default",
            auth: true,
            roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive'],
        },
    }
]
