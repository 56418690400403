export default [
    {
        path: '/city',
        name: 'city.index',
        component: () => import(/* webpackChunkName: "city" */ '../views/City/City.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration'],
        },
    },
    {
        path: '/add-city',
        name: 'city.store',
        component: () => import(/* webpackChunkName: "addCity" */ '../views/City/AddCity.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration'],
        }
    },
    {
        path: '/city/:id/edit',
        name: 'city.edit',
        component: () => import(/* webpackChunkName: "editCity" */ '../views/City/EditCity.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration'],
        }
    }
]
