export default [
    {
        path: '/roles',
        name: 'roles.index',
        component: () => import(/* webpackChunkName: "roles" */ '../views/Roles/Roles.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration'],
        },
    },
    {
        path: '/add-roles',
        name: 'roles.store',
        component: () => import(/* webpackChunkName: "addRoles" */ '../views/Roles/AddRoles.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration'],
        }
    },
    {
        path: '/roles/:id/edit',
        name: 'roles.edit',
        component: () => import(/* webpackChunkName: "editRoles" */ '../views/Roles/EditRoles.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration'],
        }
    }
]
