export default [
    {
        path: '/invoice:subscriber_id?',
        name: 'invoice.index',
        component: () => import(/* webpackChunkName: "invoice" */ '../views/Invoice/Invoice.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        },
    },
    {
        path: '/invoice/:id/view',
        name: 'invoice.view',
        component: () => import(/* webpackChunkName: "viewInvoice" */ '../views/Invoice/ViewInvoice.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        },
    },
    {
        path: '/invoicePDF/:id',
        name: 'InvoicePDF',
        component: () => import(/* webpackChunkName: "invoicePDF" */ '../views/Invoice/InvoicePDF.vue'),
        meta: {
            auth: true,
            layout: "empty"
        }
    },
    {
        path: '/my-invoices',
        name: 'MyInvoices',
        component: () => import(/* webpackChunkName: "myInvoices" */ '../views/Invoice/MyInvoices.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['client'],
        }
    },
    {
        path: '/view-invoice/:id',
        name: 'ViewInvoice',
        component: () => import(/* webpackChunkName: "viewInvoice" */ '../views/Invoice/ViewInvoice.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['client'],
        },
    },
	{
		path: '/shipping-invoice/:id?',
		name: 'shippingInvoice.index',
		component: () => import(/* webpackChunkName: "shippingInvoice" */ '../views/Invoice/ShippingInvoice.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		}
	},
	{
		path: '/packingInvoicePDF/:id',
		name: 'PackingInvoicePDF',
		component: () => import(/* webpackChunkName: "packingInvoicePDF" */ '../views/Invoice/PackingInvoicePDF.vue'),
		meta: {
			auth: true,
			layout: "empty"
		}
	},
]
