// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/swiper.less';
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/thumbs/thumbs.min.css"

// import Swiper core and required modules
import SwiperCore, {
	Navigation,Thumbs
} from 'swiper/core';

// install Swiper modules
const bootSwiper = app => {
	app.component('swiper', Swiper);
	app.component('swiper-slide', SwiperSlide);
	SwiperCore.use([Navigation,Thumbs]);
}

export { bootSwiper };
