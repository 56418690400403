import {
	ElAvatar,
	ElAutocomplete,
	ElAutoResizer,
	ElBadge,
	ElButton,
	ElCard,
	ElCheckbox,
	ElCollapse,
	ElCollapseItem,
	ElCollapseTransition,
	ElCol,
	ElDatePicker,
	ElDialog,
	ElDivider,
	ElDrawer,
	ElDropdown,
	ElDropdownMenu,
	ElDropdownItem,
	ElIcon,
	ElInput,
	ElInputNumber,
	ElMenu,
	ElMenuItem,
	ElMenuItemGroup,
	ElOption,
	ElPagination,
	ElPopper,
	ElPopover,
	ElProgress,
	ElRadio,
	ElRadioButton,
	ElRadioGroup,
	ElRow,
	ElSelect,
	ElStep,
	ElSteps,
	ElSubMenu,
	ElSwitch,
	ElTable,
	ElTableV2,
	ElTableColumn,
	ElTabs,
	ElTabPane,
	ElTag,
	ElText,
	ElTimeline,
	ElTimelineItem,
	ElTimeSelect,
	ElTooltip,
	ElTree,
	ElUpload,
	ElInfiniteScroll,
	ElMessage,
	ElMessageBox,
	ElNotification,
} from 'element-plus';

const bootElementUI = app => {
	app.component(ElAvatar.name, ElAvatar);
	app.component(ElAutocomplete.name, ElAutocomplete);
	app.component(ElAutoResizer.name, ElAutoResizer);
	app.component(ElBadge.name, ElBadge);
	app.component(ElButton.name, ElButton);
	app.component(ElCard.name, ElCard);
	app.component(ElCheckbox.name, ElCheckbox);
	app.component(ElCollapse.name, ElCollapse);
	app.component(ElCollapseItem.name, ElCollapseItem);
	app.component(ElCollapseTransition.name, ElCollapseTransition);
	app.component(ElCol.name, ElCol);
	app.component(ElDatePicker.name, ElDatePicker);
	app.component(ElDialog.name, ElDialog);
	app.component(ElDivider.name, ElDivider);
	app.component(ElDrawer.name, ElDrawer);
	app.component(ElDropdown.name, ElDropdown);
	app.component(ElDropdownMenu.name, ElDropdownMenu);
	app.component(ElDropdownItem.name, ElDropdownItem);
	app.component(ElIcon.name, ElIcon);
	app.component(ElInput.name, ElInput);
	app.component(ElInputNumber.name, ElInputNumber);
	app.component(ElMenu.name, ElMenu);
	app.component(ElMenuItem.name, ElMenuItem);
	app.component(ElMenuItemGroup.name, ElMenuItemGroup);
	app.component(ElOption.name, ElOption);
	app.component(ElPagination.name, ElPagination);
	app.component(ElPopper.name, ElPopper);
	app.component(ElPopover.name, ElPopover);
	app.component(ElProgress.name, ElProgress);
	app.component(ElRadio.name, ElRadio);
	app.component(ElRadioButton.name, ElRadioButton);
	app.component(ElRadioGroup.name, ElRadioGroup);
	app.component(ElRow.name, ElRow);
	app.component(ElSelect.name, ElSelect);
	app.component(ElStep.name, ElStep);
	app.component(ElSteps.name, ElSteps);
	app.component(ElSubMenu.name, ElSubMenu);
	app.component(ElSwitch.name, ElSwitch);
	app.component(ElTable.name, ElTable);
	app.component(ElTableV2.name, ElTableV2);
	app.component(ElTableColumn.name, ElTableColumn);
	app.component(ElTabs.name, ElTabs);
	app.component(ElTabPane.name, ElTabPane);
	app.component(ElTag.name, ElTag);
	app.component(ElText.name, ElText);
	app.component(ElTimeline.name, ElTimeline);
	app.component(ElTimelineItem.name, ElTimelineItem);
	app.component(ElTimeSelect.name, ElTimeSelect);
	app.component(ElTooltip.name, ElTooltip);
	app.component(ElTree.name, ElTree);
	app.component(ElUpload.name, ElUpload);

	app.use(ElInfiniteScroll);
	app.use(ElMessage);
	app.use(ElMessageBox);
	app.use(ElNotification);
}

export { bootElementUI };
