<template>
    <div class="max-w-full">
        <header-nav v-model:collapseSidebar="collapseSidebar" />
        <div class="wrapper">
            <side-bar v-model:collapseSidebar="collapseSidebar" />
            <!--Content-->
            <div :class="collapseSidebar ? 'middle': 'middle-collapse'" >
				<div class="h-20 md:bg-navy-blue"></div>
                <slot />
                <main-footer/>
            </div>
            <!--&lt;!&ndash;Right bar goes here, in future&ndash;&gt;-->
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import HeaderNav from '../components/layouts/HeaderNav.vue';
import SideBar from '../components/layouts/SideBar.vue';
import MainFooter from '../components/layouts/MainFooter.vue';
import { mapActions, mapGetters } from 'vuex'

export default defineComponent({
    name: 'DefaultLayout',

    components: {
        HeaderNav,
        SideBar,
        MainFooter
    },

    data() {
        return {
            collapseSidebar: false,
        };
    },

	computed: {
    	...mapGetters([
			'getAuthUser',
    		'getConfigs'
		])
	},

    methods: {
		...mapActions([
			'loadAuthUser'
		]),

        updateCollapseSidebar(event) {
            this.collapseSidebar = event;
        },
    },

	mounted() {
        if ( Object.prototype.hasOwnProperty.call(this.getConfigs, 'counters') ) {
            this.emitter.emit('favorite-counter', this.getConfigs.counters[0].favorite);
            this.emitter.emit('cart-counter', this.getConfigs.counters[0].cart);
        }
	},

	async created() {
		if (this.$store.getters.isAuthenticated) {
			await this.$store.dispatch('loadAuthUser');
			await this.$store.dispatch('loadConfigs');

			// Get the state of the current user
			const user = this.$store.getters.getAuthUser;
			// Set the current selected subscriber id
			if (user && user.subscriber_id) {
				this.$store.commit('setCurrentSelectedSubscriberId', user.subscriber_id);
			}

			if (user && user.isAdmin) {
				this.$store.commit('setCurrentSelectedSubscriberId', localStorage.getItem('subscriber_id'));
			}
		}
    },


});
</script>

<style scoped>
    .wrapper {
        display: flex;
        overflow: hidden;
        height: 100vh;
        text-align: left;
    }
    /* WebKit inertia scrolling is being added here for any present/future devices that are able to make use of it. */
    .middle,
    .middle-collapse {
        flex: 1;
        background-color: #f8f9fe;
        overflow-y: scroll;
    }
    /*No scrollbar*/
    .middle::-webkit-scrollbar { width: 0 !important }
    .middle-collapse::-webkit-scrollbar { width: 0 !important }
    .middle, .middle-collapse {
        overflow: -moz-scrollbars-none;
        scrollbar-width: none; }
    .middle, middle-collapse { -ms-overflow-style: none; }

    .header_top {
        width: 100%;
        height: 115px;
        background-color: #0057ff;
    }

</style>
