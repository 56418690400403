export default {
	createQuote: false,
	createOrderFromCart: false,
	createOrderFromQuote: false,
	createOrderFromQuoteId: '',
	createInvoiceFromCart: false,
	createInvoiceFromOrder: false,
	createInvoiceFromOrderId: '',
	collapseSidebar: false,
}
