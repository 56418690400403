export default [
    {
        path: '/password/reset',
        name: 'reset.password',
        component: () => import(/* webpackChunkName: "resetPassword" */ '../views/User/ResetPassword.vue'),
        meta: {
            auth: false,
            layout: "empty"
        }
    },
    {
        path: '/password/reset/:token',
        name: 'reset.password.form',
        component: () => import(/* webpackChunkName: "resetPasswordForm" */ '../views/User/ResetPasswordForm.vue'),
        meta: {
            auth: false,
            layout: "empty"
        }
    },
];
