<template>
    <div class="leftside">
        <el-menu
            id="sidebar"
            default-active="2"
            class="sidebar"
            :collapse="collapseSidebar"
            text-color="#002468"
            active-text-color="#0057ff"
            :router="true"
        >
			<!-- Client Access -->
			<el-menu-item index="/client-asset-management" v-if="!subscriberAccess">
				<i class="far fa-calendar-day pl-1 fa-fw iconSchedule" />
				<span>{{ $t('sidebarNav.currentPromotion')}}</span>
			</el-menu-item>
			<el-menu-item index="/client-asset-management" v-if="!subscriberAccess">
				<i class="far fa-calendar-day pl-1 fa-fw iconSchedule" />
				<span>{{ $t('sidebarNav.product')}}</span>
			</el-menu-item>
			<el-menu-item index="/client-asset-management" v-if="!subscriberAccess">
				<i class="far fa-calendar-day pl-1 fa-fw iconSchedule" />
				<span>{{ $t('sidebarNav.newsletter')}}</span>
			</el-menu-item>
			<el-menu-item index="/client-asset-management" v-if="!subscriberAccess">
				<i class="far fa-boxes-alt pl-1 fa-fw iconAdministration" />
				<span>{{ $t('sidebarNav.assetManagement')}}</span>
			</el-menu-item>


            <!-- Scheduler -->
            <el-menu-item index="/scheduler" v-if="subscriberAccess">
                <i class="far fa-calendar-day pl-1 fa-fw iconSchedule" />
                <span>{{ $t('sidebarNav.scheduler')}}</span>
            </el-menu-item>
            <!-- Service -->
            <el-sub-menu index="2" v-if="subscriberAccess">
                <template v-slot:title>
                    <i class="far fa-toolbox pl-1 fa-fw iconSchedule" />
                    <span>{{ $t('sidebarNav.service')}}</span>
                </template>
                <router-link :to="{name:'ticket.index'}" v-if="subscriberAccess">
                    <el-menu-item index="ticket"><i class="far fa-ticket-alt pl-1 iconSchedule" /><span>{{ $t('sidebarNav.ticket')}}</span></el-menu-item>
                </router-link>
				<router-link :to="{name:'task.index'}" v-if="subscriberAccess">
					<el-menu-item index="task"><i class="far fa-thumbtack pl-1 iconSchedule" /><span>{{ $t('sidebarNav.task')}}</span></el-menu-item>
				</router-link>
            </el-sub-menu>
			<!-- Client -->
			<el-sub-menu index="1" v-if="subscriberAccess">
				<template v-slot:title>
					<i class="far fa-users pl-1 fa-fw iconClient" />
					<span>{{ $t('sidebarNav.client')}}</span>
				</template>
				<router-link :to="{name:'client.index'}" v-if="subscriberAccess">
					<el-menu-item index="/client"><i class="far fa-address-card pl-1 iconClient" /><span>{{ $t('sidebarNav.client')}}</span></el-menu-item>
				</router-link>
				<router-link :to="{name:'clientAssetManagement.index'}">
					<el-menu-item index="/client-asset-management"><i class="fal fa-boxes-alt iconClient" /><span>{{ $t('sidebarNav.assetManagement')}}</span></el-menu-item>
				</router-link>
				<router-link :to="{name:'clientTransaction.index'}" v-if="subscriberAccess">
					<el-menu-item index="/client-transaction"><i class="far fa-handshake pl-1 iconClient" /><span>{{ $t('sidebarNav.transaction')}}</span></el-menu-item>
				</router-link>
			</el-sub-menu>
			<!-- Project -->
<!--			<el-menu-item index="project" v-if="subscriberAccess">-->
<!--				<i class="far fa-project-diagram pl-1 fa-fw iconProject" />-->
<!--				<span>{{ $t('sidebarNav.project')}}</span>-->
<!--			</el-menu-item>-->
<!--            &lt;!&ndash; Lead&ndash;&gt;-->
<!--            <el-menu-item index="4" v-if="subscriberAccess">-->
<!--                <i class="far fa-bullhorn pl-1 fa-fw iconLead" />-->
<!--                <span>{{ $t('sidebarNav.lead')}}</span>-->
<!--            </el-menu-item>-->
            <!-- Quote -->
            <el-menu-item index="/quote" v-if="subscriberAccess">
                <i class="far fa-pen-alt pl-1 fa-fw iconQuote" />
                <span>{{ $t('sidebarNav.quote')}}</span>
            </el-menu-item>
			<!-- Order -->
			<el-sub-menu index="5" v-if="subscriberAccess">
				<template v-slot:title>
					<i class="far fa-box-open pl-1 fa-fw iconOrder" />
					<span>{{ $t('sidebarNav.order')}}</span>
				</template>
				<router-link :to="{name:'order.index'}">
					<el-menu-item index="order"><i class="far fa-box-open pl-1 fa-fw iconOrder" /><span>{{ $t('sidebarNav.order')}}</span></el-menu-item>
				</router-link>
				<router-link :to="{name:'shippingOrder.index'}">
					<el-menu-item index="shipping-order"><i class="far fa-box-up pl-1 pr-3 iconOrder" /><span>{{ $t('sidebarNav.shipping')}}</span></el-menu-item>
				</router-link>
			</el-sub-menu>
			<!-- Invoice -->
			<el-sub-menu index="6" v-if="subscriberAccess">
				<template v-slot:title>
					<i class="far fa-cash-register pl-1 fa-fw iconInvoice" />
					<span>{{ $t('sidebarNav.invoice')}}</span>
				</template>
				<router-link :to="{name:'invoice.index'}">
					<el-menu-item index="invoice"><i class="far fa-cash-register pl-1 fa-fw iconInvoice" /><span>{{ $t('sidebarNav.invoice')}}</span></el-menu-item>
				</router-link>
				<router-link :to="{name:'shippingInvoice.index'}">
					<el-menu-item index="shipping-invoice"><i class="far fa-box-up pl-1 pr-3 iconInvoice" /><span>{{ $t('sidebarNav.shipping')}}</span></el-menu-item>
				</router-link>
                <router-link :to="{name:'paymentRefund.index'}" v-if="accountingAccess">
                    <el-menu-item index="/payment-refund" :disabled="!accountingAccess"><i class="far fa-undo pl-1 fa-fw iconInvoice" /><span>{{ $t('sidebarNav.paymentRefund')}}</span></el-menu-item>
                </router-link>
			</el-sub-menu>
            <!-- Inventory Movement-->
            <el-menu-item index="/inventory-movement" v-if="subscriberAccess">
                <i class="far fa-shipping-fast pl-1 fa-fw iconInventory" />
                <span>{{ $t('sidebarNav.inventoryMovement')}}</span>
            </el-menu-item>
			<!-- Contract -->
			<el-sub-menu index="7" v-if="subscriberAccess">
				<template v-slot:title>
					<i class="far fa-file-signature pl-1 fa-fw iconContract" />
					<span>{{ $t('sidebarNav.contract')}}</span>
				</template>
				<router-link :to="{name:'serviceContract.index'}" v-if="subscriberAccess">
					<el-menu-item index="/service-contract"><i class="far fa-file-signature iconContract pl-1 pr-3" /><span>{{ $t('sidebarNav.service')}}</span></el-menu-item>
				</router-link>
                <router-link :to="{name:'contract.index'}" v-if="subscriberAccess">
                    <el-menu-item index="/contract"><i class="far fa-screwdriver iconContract pl-1 pr-3" /><span>{{ $t('sidebarNav.maintenance')}}</span></el-menu-item>
                </router-link>
				<router-link :to="{name:'proposal.index'}" v-if="subscriberAccess">
					<el-menu-item index="/proposal"><i class="far fa-file-search iconContract pl-1 pr-3" /><span>{{ $t('sidebarNav.proposal')}}</span></el-menu-item>
				</router-link>
				<router-link :to="{name:'serial.index'}" v-if="subscriberAccess">
					<el-menu-item index="/serial"><i class="fal fa-scanner iconContract pl-1 pr-3" /><span>{{ $t('sidebarNav.serialNumber')}}</span></el-menu-item>
				</router-link>
				<router-link :to="{name:'subscription.index'}" v-if="subscriberAccess">
					<el-menu-item index="/subscription"><i class="far fa-alarm-clock iconContract pl-1 pr-3" /><span>{{ $t('sidebarNav.subscription')}}</span></el-menu-item>
				</router-link>
				<router-link :to="{name:'invoiceProcessing.index'}" v-if="subscriberAccess">
					<el-menu-item index="/invoice-processing"><i class="far fa-microchip iconContract pl-1 pr-3" /><span>{{ $t('sidebarNav.invoiceProcessing')}}</span></el-menu-item>
				</router-link>
			</el-sub-menu>
            <!-- Product -->
            <el-sub-menu index="8" v-if="subscriberAccess">
                <template v-slot:title>
                    <i class="far fa-shopping-bag pl-1 fa-fw iconProduct" />
                    <span>{{ $t('sidebarNav.product')}}</span>
                </template>
                <router-link :to="{name:'productList.index'}" :event="!subscriberAccess ? '' : 'click'" v-if="subscriberAccess">
                    <el-menu-item index="/product-list"><i class="fal fa-box-full pl-1 pr-2 iconProduct" /><span>{{ $t('sidebarNav.productList')}}</span></el-menu-item>
                </router-link>
				<router-link :to="{name:'product.index'}" :event="!subscriberAccess ? '' : 'click'" v-if="subscriberAccess">
					<el-menu-item index="/product"><i class="fal fa-sparkles pl-1 pr-2 iconProduct" /><span>{{ $t('sidebarNav.newProduct')}}</span></el-menu-item>
				</router-link>
				<router-link :to="{name:'attribute.index'}" :event="!subscriberAccess ? '' : 'click'" v-if="subscriberAccess">
					<el-menu-item index="/attribute"><i class="fal fa-ellipsis-v-alt pl-2 pr-2 iconProduct" /><span>{{ $t('sidebarNav.attribute')}}</span></el-menu-item>
				</router-link>
				<router-link :to="{name:'manufacturer.index'}" :event="!subscriberAccess ? '' : 'click'" v-if="subscriberAccess">
					<el-menu-item index="/manufacturer"><i class="fal fa-industry-alt pl-1 pr-2 iconProduct" /><span>{{ $t('sidebarNav.manufacturer')}}</span></el-menu-item>
				</router-link>
				<router-link :to="{name:'batch.index'}" :event="!subscriberAccess ? '' : 'click'" v-if="subscriberAccess">
					<el-menu-item index="/batch"><i class="fal fa-pallet-alt pl-1 pr-2 iconProduct" /><span>{{ $t('sidebarNav.batch')}}</span></el-menu-item>
				</router-link>
				<router-link :to="{name:'serialBank.index'}" :event="!subscriberAccess ? '' : 'click'" v-if="subscriberAccess">
					<el-menu-item index="/serial-bank"><i class="fal fa-scanner pl-1 pr-2 iconProduct" /><span>{{ $t('sidebarNav.serial')}}</span></el-menu-item>
				</router-link>
            </el-sub-menu>
			<!-- Supplier -->
			<el-sub-menu index="9" v-if="subscriberAccess">
				<template v-slot:title>
					<i class="far fa-industry pl-1 iconSupplier" />
					<span>{{ $t('sidebarNav.supplier')}}</span>
				</template>
				<router-link :to="{name:'supplier.index'}">
					<el-menu-item index="/supplier"><i class="far fa-address-card pl-1 iconSupplier" /><span>{{ $t('sidebarNav.supplier')}}</span></el-menu-item>
				</router-link>
				<router-link :to="{name:'purchaseOrder.index'}">
					<el-menu-item index="/purchase-order"><i class="far fa-truck pl-1 iconSupplier" /><span>{{ $t('sidebarNav.purchaseOrder')}}</span></el-menu-item>
				</router-link>
				<router-link :to="{name:'reception.index'}">
					<el-menu-item index="/reception"><el-icon class="pl-1 iconSupplier"><finished /></el-icon><span>{{ $t('sidebarNav.reception')}}</span></el-menu-item>
				</router-link>
                <router-link :to="{name:'creditNote.index'}" v-if="accountingAccess">
                    <el-menu-item index="/credit-note" :disabled="!accountingAccess"><i class="far fa-box-fragile pl-1 fa-fw iconSupplier" /><span>{{ $t('sidebarNav.creditNote')}}</span></el-menu-item>
                </router-link>
			</el-sub-menu>
			<!-- Marketing -->
			<el-sub-menu index="12" v-if="subscriberAccess">
				<template v-slot:title>
					<i class="far fa-signal-stream pl-1 iconMarketing" />
					<span>{{ $t('sidebarNav.marketing')}}</span>
				</template>
				<router-link :to="{name:'emailGroups.index'}">
					<el-menu-item index="/email-groups"><i class="far fa-layer-group pl-1 iconMarketing" /><span>{{ $t('sidebarNav.emailGroups')}}</span></el-menu-item>
				</router-link>
				<router-link :to="{name:'emails.index'}">
					<el-menu-item index="/emails"><i class="far fa-at pl-1 iconMarketing" /><span>{{ $t('sidebarNav.emails')}}</span></el-menu-item>
				</router-link>
				<router-link :to="{name:'newsletter.index'}">
					<el-menu-item index="/newsletter"><i class="far fa-mail-bulk pl-1 iconMarketing" /><span>{{ $t('sidebarNav.newsletter')}}</span></el-menu-item>
				</router-link>
				<router-link :to="{name:'promotion.index'}">
					<el-menu-item index="/promotion"><i class="far fa-badge-percent pl-1 iconMarketing" /><span>{{ $t('sidebarNav.promotion')}}</span></el-menu-item>
				</router-link>
				<router-link :to="{name:'productShowcase.index'}">
					<el-menu-item index="/product-showcase"><i class="far fa-box-heart pl-1 iconMarketing" /><span>{{ $t('sidebarNav.productShowcase')}}</span></el-menu-item>
				</router-link>
			</el-sub-menu>
			<!-- Accounting-->
			<template v-if="accountingVisible">
				<el-sub-menu index="10">
					<template v-slot:title>
						<i class="fal fa-calculator-alt pl-1 pr-2 iconAccounting" />
						<span>{{ $t('sidebarNav.accounting')}}</span>
					</template>
					<router-link :to="{name:'account.index'}" v-if="accountingAccess">
						<el-menu-item index="/account" :disabled="!accountingAccess"><i class="fal fa-university pl-1 iconAccounting" /><span>{{ $t('sidebarNav.chartOfAccounts')}}</span></el-menu-item>
					</router-link>
					<router-link :to="{name:'creditCard.index'}" v-if="accountingAccess">
						<el-menu-item index="/credit-card" :disabled="!accountingAccess"><i class="fal fa-credit-card fa-fw pl-1 iconAccounting" /><span>{{ $t('sidebarNav.creditCard')}}</span></el-menu-item>
					</router-link>
					<router-link :to="{name:'bankReconciliation.index'}" v-if="accountingAccess">
						<el-menu-item index="/bank-reconciliation" :disabled="!accountingAccess"><i class="far fa-search-dollar fa-fw pl-1 iconAccounting" /><span>{{ $t('sidebarNav.bankReconciliation')}}</span></el-menu-item>
					</router-link>
					<router-link :to="{name:'journal-entry.index'}" v-if="accountingAccess">
						<el-menu-item index="/journal-entry" :disabled="!accountingAccess"><i class="fal fa-abacus fa-fw pl-1 iconAccounting" /><span>{{ $t('sidebarNav.journalEntry')}}</span></el-menu-item>
					</router-link>
					<router-link :to="{name:'revenue-category.index'}" v-if="accountingAccess">
						<el-menu-item index="/revenue-category"><i class="fal fa-badge-dollar pl-1 iconAccounting" /><span>{{ $t('sidebarNav.revenueCategory')}}</span></el-menu-item>
					</router-link>
					<router-link :to="{name:'expenses-category.index'}" v-if="accountingAccess">
						<el-menu-item index="/expense-category"><i class="fal fa-file-invoice-dollar pl-1 iconAccounting" /><span>{{ $t('sidebarNav.expensesCategory')}}</span></el-menu-item>
					</router-link>
					<router-link :to="{name:'tax-remittance.index'}" v-if="accountingAccess">
						<el-menu-item index="/tax-remittance"><i class="fal fa-funnel-dollar pl-1 iconAccounting" /><span>{{ $t('sidebarNav.taxRemittance')}}</span></el-menu-item>
					</router-link>
					<router-link :to="{name:'closing'}" v-if="accountingAccess">
						<el-menu-item index="/closing"><i class="fal fa-treasure-chest pl-1 iconAccounting" /><span>{{ $t('sidebarNav.closeTheBooks')}}</span></el-menu-item>
					</router-link>
				</el-sub-menu>
				<!-- Expense -->
				<el-sub-menu index="11">
					<template v-slot:title>
						<i class="far fa-credit-card-front pl-1 pr-2 iconPayable" />
						<span>{{ $t('sidebarNav.expense')}}</span>
					</template>
					<router-link :to="{name:'expense.index'}" v-if="accountingAccess">
						<el-menu-item index="/expense" :disabled="!accountingAccess"><i class="far fa-credit-card-front pl-1 fa-fw iconPayable" /><span>{{ $t('sidebarNav.expenseCOGS')}}</span></el-menu-item>
					</router-link>
                    <router-link :to="{name:'operationalExpense.index'}" v-if="accountingAccess">
                        <el-menu-item index="/operational-expense" :disabled="!accountingAccess"><i class="far fa-bolt pl-1 fa-fw iconPayable" /><span>{{ $t('sidebarNav.operationalExpense')}}</span></el-menu-item>
                    </router-link>
					<router-link :to="{name:'expensePayment.index'}" v-if="accountingAccess">
						<el-menu-item index="/expense-payment" :disabled="!accountingAccess"><i class="far fa-envelope-open-dollar pl-1 fa-fw iconPayable" /><span>{{ $t('sidebarNav.payment')}}</span></el-menu-item>
					</router-link>
				</el-sub-menu>
				<!-- Receivable -->
				<el-sub-menu index="17">
					<template v-slot:title>
						<i class="far fa-sack-dollar pl-1 pr-2 iconReceivable" />
						<span>{{ $t('sidebarNav.receivable')}}</span>
					</template>
					<router-link :to="{name:'receivable.index'}" v-if="accountingAccess">
						<el-menu-item index="/receivable" :disabled="!accountingAccess"><i class="far fa-sack-dollar pl-1 fa-fw iconReceivable" /><span>{{ $t('sidebarNav.receivable')}}</span></el-menu-item>
					</router-link>
					<router-link :to="{name:'receivablePayment.index'}" v-if="accountingAccess">
						<el-menu-item index="/receivable-payment" :disabled="!accountingAccess"><i class="far fa-hand-holding-usd pl-1 fa-fw iconReceivable" /><span>{{ $t('sidebarNav.payment')}}</span></el-menu-item>
					</router-link>
				</el-sub-menu>
			</template>
			<!-- Report -->
			<el-menu-item index="/report" v-if="subscriberAccess">
				<i class="far fa-chart-pie-alt pl-1 fa-fw iconReport" />
				<span>{{ $t('sidebarNav.report')}}</span>
			</el-menu-item>
			<!-- Settings -->
			<el-sub-menu index="13" v-if="subscriberAccess">
				<template v-slot:title>
					<i class="far fa-tools pl-1 fa-fw iconSetup" />
					<span>{{ $t('sidebarNav.settings')}}</span>
				</template>
				<router-link :to="{name:'productCategorization.index'}" v-if="salesAccess">
					<el-menu-item index="product-categorization"><i class="far fa-folder-tree pl-1 iconSetup" /><span>{{ $t('sidebarNav.productCategorization')}}</span></el-menu-item>
				</router-link>
				<router-link :to="{name:'productTemplate.index'}">
					<el-menu-item index="/product-template"><i class="far fa-ruler-triangle pl-1 iconSetup" /><span>{{ $t('sidebarNav.productTemplate')}}</span></el-menu-item>
				</router-link>
				<router-link :to="{name:'customField.index'}">
					<el-menu-item index="/custom-field"><i class="far fa-file-code  pl-1 iconSetup" /><span>{{ $t('sidebarNav.customField')}}</span></el-menu-item>
				</router-link>
				<router-link :to="{name:'defaultParams.index'}" v-if="accountingAccess">
					<el-menu-item index="/default-params"><i class="far fa-sliders-v  pl-1 iconSetup" /><span>{{ $t('sidebarNav.defaultParams')}}</span></el-menu-item>
				</router-link>
				<router-link :to="{name:'taxAccount.index'}" v-if="accountingAccess">
					<el-menu-item index="/tax-account"><i class="far fa-balance-scale-right pl-1 iconSetup" /><span>{{ $t('sidebarNav.taxAccount')}}</span></el-menu-item>
				</router-link>
				<router-link :to="{name:'serviceRate.index'}" v-if="accountingAccess">
					<el-menu-item index="/service-rate"><i class="far fa-tachometer-slow pl-1 iconSetup" /><span>{{ $t('sidebarNav.serviceRate')}}</span></el-menu-item>
				</router-link>
                <router-link :to="{name:'ticketStatus.index'}" v-if="accountingAccess">
                    <el-menu-item index="/ticket-status"><i class="far fa-clipboard-list-check pl-1 iconSetup" /><span>{{ $t('sidebarNav.ticketStatus')}}</span></el-menu-item>
                </router-link>
                <router-link :to="{name:'taskSetting.index'}" v-if="accountingAccess">
                    <el-menu-item index="/task-setting"><i class="far fa-megaphone pl-1 iconSetup" /><span>{{ $t('sidebarNav.taskType')}}</span></el-menu-item>
                </router-link>
				<router-link :to="{name:'contractDiscount.index'}" v-if="accountingAccess">
					<el-menu-item index="/contract-discount"><i class="far fa-percent pl-1 iconSetup" /><span>{{ $t('sidebarNav.contractDiscount')}}</span></el-menu-item>
				</router-link>
			</el-sub-menu>
			<!-- Administration -->
			<el-sub-menu index="14" v-if="subscriberAccess">
				<template v-slot:title>
					<i class="far fa-lock-alt pl-1 fa-fw iconAdministration" />
					<span>{{ $t('sidebarNav.administration')}}</span>
				</template>
				<router-link :to="{name:'assetManagement.index'}">
					<el-menu-item index="/asset-management"><i class="fal fa-boxes-alt iconAdministration" /><span>{{ $t('sidebarNav.assetManagement')}}</span></el-menu-item>
				</router-link>
				<router-link :to="{name:'user.index'}">
					<el-menu-item index="/user"><i class="fal fa-user-lock iconAdministration" /><span>{{ $t('sidebarNav.user')}}</span></el-menu-item>
				</router-link>
			</el-sub-menu>
            <!-- Settings back office-->
            <template v-if="getAuthUser.isAdmin">
                <hr class="mx-3">
                <div class="ml-3 text-gray-500 text-sm">BACK OFFICE</div>
                <el-sub-menu index="15">
                    <template v-slot:title>
                        <i class="fal fa-users-cog pl-1 fa-fw iconSettings" />
                        <span>{{ $t('sidebarNav.accountManagement')}}</span>
                    </template>
					<router-link :to="{name:'subscriber.index'}">
						<el-menu-item index="/subscriber"><i class="fal fa-building pl-1 fa-fw iconSettings" /><span>{{ $t('sidebarNav.subscriber')}}</span></el-menu-item>
					</router-link>
					<router-link :to="{name:'staff.index'}">
						<el-menu-item index="/staff"><i class="fal fa-user-shield pl-1 iconSettings" /><span>{{ $t('sidebarNav.staff')}}</span></el-menu-item>
					</router-link>
                </el-sub-menu>
                <el-sub-menu index="16">
                    <template v-slot:title>
                        <i class="fal fa-cog fa-fw iconSettings" />
                        <span>{{ $t('sidebarNav.configuration')}}</span>
                    </template>
					<router-link :to="{name:'country.index'}">
						<el-menu-item index="/country"><i class="fal fa-globe pl-1 fa-fw iconSettings" /><span>{{ $t('sidebarNav.country')}}</span></el-menu-item>
					</router-link>
					<router-link :to="{name:'province.index'}">
						<el-menu-item index="/province"><i class="fal fa-map-marked-alt pl-1 fa-fw iconSettings" /><span>{{ $t('sidebarNav.province')}}</span></el-menu-item>
					</router-link>
					<router-link :to="{name:'city.index'}">
						<el-menu-item index="/city"><i class="fal fa-map-marker-alt pl-1 fa-fw iconSettings" /><span>{{ $t('sidebarNav.city')}}</span></el-menu-item>
					</router-link>
					<router-link :to="{name:'tax.index'}">
						<el-menu-item index="/tax"><i class="fal fa-file-invoice-dollar pl-1 fa-fw iconSettings" /><span>{{ $t('sidebarNav.tax')}}</span></el-menu-item>
					</router-link>
					<router-link :to="{name:'bank.index'}">
						<el-menu-item index="/bank"><i class="fal fa-landmark pl-1 fa-fw iconSettings" /><span>{{ $t('sidebarNav.bank')}}</span></el-menu-item>
					</router-link>
					<router-link :to="{name:'productAttribute.index'}">
						<el-menu-item index="/product-attribute"><i class="fal fa-tasks pl-1 fa-fw iconSettings" /><span>{{ $t('sidebarNav.productAttribute')}}</span></el-menu-item>
					</router-link>
                    <router-link :to="{name:'roles.index'}">
                        <el-menu-item index="/roles"><i class="fal fa-shield-check pl-1 fa-fw iconSettings" /><span>{{ $t('sidebarNav.roles')}}</span></el-menu-item>
                    </router-link>
					<router-link :to="{name:'paymentTerm.index'}">
						<el-menu-item index="/payment-term"><i class="fal fa-money-check-edit-alt pl-1 fa-fw iconSettings" /><span>{{ $t('sidebarNav.paymentTerm')}}</span></el-menu-item>
					</router-link>
                    <router-link :to="{name:'taskType.index'}">
                        <el-menu-item index="/task-type"><i class="fal fa-megaphone pl-1 fa-fw iconSettings" /><span>{{ $t('sidebarNav.taskType')}}</span></el-menu-item>
                    </router-link>
                </el-sub-menu>

            </template>
        </el-menu>
        <side-bar-footer :collapseSidebar="collapseSidebar"/>
    </div>
</template>

<script>
	import { Finished } from '@element-plus/icons-vue';
	import SideBarFooter from './SideBarFooter.vue';
    import { mapGetters } from 'vuex';

    export default {
        name: 'SideBar',

        components: {
			SideBarFooter,
			Finished,
		},

        props: {
            collapseSidebar: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapGetters([
                'getAuthUser',
            ]),

            subscriberAccess() {
                const roleTypes = ['administration', 'subscriber'];
                return roleTypes.includes(this.getAuthUser.role.type);
            },

            accountingAccess() {
                const roles = ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'];
                return roles.includes(this.getAuthUser.role.slug);
            },

            salesAccess() {
                const roles = ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'sale'];
                return roles.includes(this.getAuthUser.role.slug);
            },

            accountingVisible() {
                return this.accountingAccess;
            }
        }
    }
</script>

<style scoped>
    .sidebar {
        width: 260px;
    }


	.el-menu--collapse {
		width: 64px;
	}


    .el-menu-item span {
        position: absolute;
        left: 52px;
        font-size: 16px;
		color: #002468;
    }
    .el-sub-menu__title span {
        position: absolute;
        left: 52px;
        font-size: 16px;
    }

    .el-sub-menu .el-menu-item span{
        left: 72px;
    }

    /* Highlight active menu item */
    li.el-menu-item.is-active  {
      background-color: #e6eeff;
    }

    .leftside {
        overflow-y: auto;
    }
    /*No scrollbar*/
    .leftside::-webkit-scrollbar { width: 0 !important }
    .leftside {
        overflow: -moz-scrollbars-none;
        scrollbar-width: none;
    }
    .leftside {
        -ms-overflow-style: none;
    }

    .iconSchedule {
        color: #f79b3d;
    }
	.iconClient {
		color: #2962ff;
	}
    .iconService {
        color: #0400a5;
    }
	.iconProject {
		color: #6200ea;
	}
    .iconLead {
		color: #311b92;
    }
    .iconQuote {
		color: #67c23a;
    }
    .iconOrder {
        color: #ff6d00;
    }
    .iconInvoice {
        color: #b71c1c;
    }
	.iconContract {
		color: #ca2c92;
	}
	.iconProduct {
        color: #880e4f;
    }
	.iconSupplier {
		color: #92400e;
	}
	.iconMarketing {
		color: #ca8a04;
	}
    .iconAccounting {
		color: #1b5e20;
    }
	.iconPayable {
		color: #827717;
	}
	.iconReceivable {
		color: #996633;
	}
	.iconReport {
		color: #ffc107;
	}
	.iconSetup {
		color: #00b0ff;
	}
	.iconSettings {
		color: #002468;
	}
	.iconAdministration {
		color: #002468;
	}
    .iconInventory {
        color: #c51162;
    }
</style>
