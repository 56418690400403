export default [
    {
        path: '/user',
        name: 'user.index',
        component: () => import(/* webpackChunkName: "user" */ '../views/User/User.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        },
    },
    {
        path: '/add-user',
        name: 'user.store',
        component: () => import(/* webpackChunkName: "addUser" */ '../views/User/AddUser.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        },
    },
    {
        path: '/user/:id/edit',
        name: 'user.edit',
        component: () => import(/* webpackChunkName: "editUser" */ '../views/User/EditUser.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        },
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/User/Profile.vue'),
        meta: {
			layout: "default",
            auth: true
        }
    },
]
