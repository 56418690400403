export default {
    client: {},
    currentSelectedSubscriberId: '',
    currentSelectedUserId: '',
    defaultParams: {},
    order: {},
    quote: {},
    site: {},
    cache: {},
}
