export default [
	{
		path: '/subscription',
		name: 'subscription.index',
		component: () => import(/* webpackChunkName: "subscription" */ '../views/Subscription/Subscription.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/subscription/:id/edit',
		name: 'subscription.edit',
		component: () => import(/* webpackChunkName: "editSubscription" */ '../views/Subscription/EditSubscription.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		}
	},
	{
		path: '/add-subscription',
		name: 'subscription.store',
		component: () => import(/* webpackChunkName: "addSubscription" */ '../views/Subscription/AddSubscription.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/previewSubscriptionInvoicePDF',
		name: 'PreviewSubscriptionInvoicePDF',
		component: () => import(/* webpackChunkName: "previewSubscriptionInvoicePDF" */ '../views/Subscription/PreviewSubscriptionInvoicePDF.vue'),
		meta: {
			auth: true,
			layout: "empty"
		}
	},
	{
		path: '/subscription-tc',
		name: 'subscriptionTC.index',
		component: () => import(/* webpackChunkName: "subscriptionTC" */ '../views/Subscription/SubscriptionTC.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive'],
		},
	},
	{
		path: '/subscriptionPDF/:id',
		name: 'SubscriptionPDF',
		component: () => import(/* webpackChunkName: "subscriptionPDF" */ '../views/Subscription/SubscriptionPDF.vue'),
		meta: {
			auth: true,
			layout: "empty"
		}
	},
]
