export default [
	{
		path: '/asset-management',
		name: 'assetManagement.index',
		component: () => import(/* webpackChunkName: "assetManagement" */ '../views/AssetManagement/AssetManagement.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
]
