<template>
    <div class="loader">
        <div class="loadingColor text-center mt-4">
			<el-icon :size="20" class="is-loading">
				<loading />
			</el-icon>
        </div>
    </div>

</template>

<script>
import {Loading} from '@element-plus/icons-vue';

export default {
	name: 'AppLoading',

	components: {
		Loading
	}
}
</script>

<style lang="less">
    div.loader {
        height: 30px;
    }

    div.loadingColor .el-icon-loading {
        color: #606266;
        font-size: larger;
        margin-top: 10px;
    }
</style>
