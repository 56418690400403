export default {
    getToken(state) {
        return state.token;
    },

    getAuthUser(state) {
        return state;
    },

    isAuthenticated(state) {
        return !!state;
    },
}
