import axios from 'axios';
import api from '../../../plugins/api.js'

export default {
    async login({commit}, payload) {
	    const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/auth/login`, payload);
        commit('setAuthUser', response.data.user);

		if (response) {
			localStorage.setItem('access_token', response.data.access_token);
			localStorage.setItem('auth_user', JSON.stringify(response.data.user));
		}

		if (response && response.data.user.subscriber_id) {
			localStorage.setItem('subscriber_id', response.data.user.subscriber_id);
		}
    },

    async tryLogin({commit}) {
		const user = JSON.parse(localStorage.getItem('auth_user'));
		const token = localStorage.getItem('access_token');
		if (token && user) {
			commit('setAuthUser', user);
		}
    },

    async logout({commit}) {
        await api().post(`${process.env.VUE_APP_BASE_URL}/auth/logout`);
        commit('setLogout', {});
		commit('setCurrentSelectedSubscriberId', '');
        localStorage.removeItem('access_token');
        localStorage.removeItem('subscriber_id');
		localStorage.removeItem('auth_user');
    },

    async loadAuthUser({commit}) {
        const token = localStorage.getItem('access_token');
        if (!token) {
            return
        }

		const response = await api().get(`${process.env.VUE_APP_BASE_URL}/auth/getAuthUser`);
        commit('setAuthUser', response.data.data);
        return response.data.data;
    },

}
