export default {
    setCurrent(state, payload) {
        Object.assign(state, payload);
    },

    setDefaultParams(state, payload) {
        Object.assign(state.defaultParams, payload)
    },

    setCurrentSelectedSubscriberId(state, payload) {
        state.currentSelectedSubscriberId = payload;
		localStorage.setItem('subscriber_id', payload);
    },

    setCurrentSelectedUserId(state, payload) {
        state.currentSelectedUserId = payload;
    },

    setCurrentQuote(state, payload) {
        Object.assign(state.quote, payload);
    },

    setCurrentOrder(state, payload) {
        Object.assign(state.order, payload);
    },

    setScheduleUsers(state, payload) {
        state.cache.scheduler_users = payload.scheduler_users;
        state.cache.scheduler_date = payload.scheduler_date;
        state.cache.scheduler_display = payload.scheduler_display;
    },
}
