export default [
    {
        path: '/revenue-category',
        name: 'revenue-category.index',
        component: () => import(/* webpackChunkName: "revenueCategory" */ '../views/RevenueCategory/RevenueCategory.vue'),
        meta: {
			layout: "default",
            auth: true,
            roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive'],
        },
    },
    {
        path: '/add-revenue-category',
        name: 'revenue-category.store',
        component: () => import(/* webpackChunkName: "addRevenueCategory" */ '../views/RevenueCategory/AddRevenueCategory.vue'),
        meta: {
			layout: "default",
            auth: true,
            roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive'],
        },
    },
    {
        path: '/revenue-category/:id/edit',
        name: 'revenue-category.edit',
        component: () => import(/* webpackChunkName: "editRevenueCategory" */ '../views/RevenueCategory/EditRevenueCategory.vue'),
        meta: {
			layout: "default",
            auth: true,
            roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive'],
        },
    }
]
