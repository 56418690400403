export default [
	{
		path: '/journal-entry',
		name: 'journal-entry.index',
		component: () => import(/* webpackChunkName: "journalEntry" */ '../views/Accounting/JournalEntry/JournalEntry.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'],
		},
	},
	{
		path: '/add-journal-entry',
		name: 'journal-entry.store',
		component: () => import(/* webpackChunkName: "addJournalEntry" */ '../views/Accounting/JournalEntry/AddJournalEntry.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'],
		},
	},
	{
		path: '/journal-entry/:id/edit',
		name: 'journal-entry.edit',
		component: () => import(/* webpackChunkName: "editJournalEntry" */ '../views/Accounting/JournalEntry/EditJournalEntry.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'],
		},
	},
	{
		path: '/tax-remittance',
		name: 'tax-remittance.index',
		component: () => import(/* webpackChunkName: "taxRemittance" */ '../views/Accounting/Tax/SalesTaxRemittance.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'accounting'],
		},
	},
	{
		path: '/closing',
		name: 'closing',
		component: () => import(/* webpackChunkName: "closing" */ '../views/Accounting/Closing/Closing.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'accounting'],
		},
	},
	{
		path: '/bank-reconciliation',
		name: 'bankReconciliation.index',
		component: () => import(/* webpackChunkName: "bankReconciliation" */ '../views/Accounting/Bank/BankReconciliation.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'accounting'],
		},
	},
	{
		path: '/start-reconciling',
		name: 'startReconciling',
		component: () => import(/* webpackChunkName: "startReconciling" */ '../views/Accounting/Bank/StartReconciling.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'accounting'],
		},
	},
	{
		path: '/bank-reconciliation-statement/:id',
		name: 'bankReconciliationStatement.show',
		component: () => import(/* webpackChunkName: "viewBankReconciliationStatement" */ '../views/Accounting/Bank/ViewBankReconciliationStatement.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'accounting'],
		},
	},
	{
		path: '/credit-card',
		name: 'creditCard.index',
		component: () => import(/* webpackChunkName: "creditCard" */ '../views/Accounting/CreditCard/CreditCard.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'accounting'],
		},
	},
	{
		path: '/add-credit-card-statement',
		name: 'creditCardStatement.store',
		component: () => import(/* webpackChunkName: "addCreditCardStatement" */ '../views/Accounting/CreditCard/AddCreditCardStatement.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'accounting'],
		},
	},
	{
		path: '/credit-card-statement/:id',
		name: 'creditCardStatement.show',
		component: () => import(/* webpackChunkName: "viewCreditCardStatement" */ '../views/Accounting/CreditCard/ViewCreditCardStatement.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'accounting'],
		},
	},
]
