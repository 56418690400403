import { createI18n } from 'vue-i18n';

let locale = 'en';

if (typeof localStorage !== 'undefined') {
    locale = localStorage.getItem('currentLocale');

    if (!locale) {
        locale = navigator.language.includes('fr') ? 'fr' : 'en';
    }

    localStorage.setItem('currentLocale', locale);
}

const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);

function loadLocaleMessages() {
    // const locales = require.context('../../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    });
    return messages;
}

function loadDateTime() {
    // const locales = require.context('../../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const datetimeFormats = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            datetimeFormats[locale] = locales(key).datetimeFormats;
        }
    });
    return datetimeFormats
}

function loadNumber() {
    // const locales = require.context('../../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const numberFormats = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            numberFormats[locale] = locales(key).numberFormats;
        }
    });
    return numberFormats
}

export const i18n = createI18n({
    locale,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages(),
    datetimeFormats: loadDateTime(),
    numberFormats: loadNumber(),
	globalInjection: true,
});


// /**
//  * @name: getLocalesData
//  * @description: Helper to load the locale json files with each locale data
//  */
// const getLocalesData = () => {
//     // we use require.context to get all the .json files under the locales sub-directory
//     const files = require.context('../../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
//     // create the instance that will hold the loaded data
//     const localeData = {
//         datetimeFormats: {},
//         numberFormats: {},
//         messages: {}
//     };
//     // loop through all the files
//     const keys = files.keys();
//     keys.forEach((key) => {
//         // extract name without extension
//         const matched = key.match(/([A-Za-z0-9-_]+)\./i);
//         if (matched && matched.length > 1) {
//             const localeId = matched[1];
//             // from each file, set the related messages property
//             localeData.datetimeFormats[localeId] = files(key).datetimeFormats;
//             localeData.numberFormats[localeId] = files(key).numberFormats;
//             localeData.messages[localeId] = files(key).messages;
//         }
//     });
//     return localeData;
// };
// // create our data dynamically by loading the JSON files through our getLocalesData helper
// const data = getLocalesData();
// // create out vue-18n instance
// export const i18n = createI18n({
//     locale: 'fr',
//     fallbackLocale: 'en',
//     messages: data.messages,
//     datetimeFormats: data.datetimeFormats,
//     numberFormats: data.numberFormats
// });
