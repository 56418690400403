<template>
    <div class="search-header bg-navy-blue h-56px ml-2 py-2">
		<el-autocomplete
            size="large"
			class="inline-input w-full"
			popper-class="my-autocomplete"
			:fetch-suggestions="querySearchAsync"
			:placeholder="$t('search.search')"
			:trigger-on-focus="false"
			v-model="searchTerm"
			@select="handleSelect"

		>
			<template #suffix class="el-input__suffix ">
				<span class="el-input__suffix-inner" @click="resetSearch" v-if="searchTerm">
					<el-icon class="cursor-pointer mr-1"><circle-close /></el-icon>
                </span>
                <span class="el-input__suffix-inner" @click="clickSearch">
                    <i class="far fa-search text-gray-700 cursor-pointer" />
                </span>
            </template>
			<template #default="{ item }">
				<div class="truncate"><span class="italic">{{ item.SKU }}</span> - {{ item[getTitle] }}</div>
			</template>
		</el-autocomplete>
    </div>
</template>

<script>
	import { CircleClose} from '@element-plus/icons-vue';
	import search from '@/api/search.js';
    import { mapGetters } from 'vuex';

	export default {
        name: 'SearchBarHeader',

		components: {
			CircleClose,
		},

        data() {
            return {
                searchTerm: '',
                timeout:  null,
                searchResults: [],
            };
        },

        computed: {
        	...mapGetters([
        		'getAuthUser'
			]),

            getTitle()
            {
                return `title_${this.$i18n.locale}`;
            },
        },

        methods: {
            // When Enter pressed
            async enterSearch(queryString) {
                await this.$router.push({ path: `/search/${this.getAuthUser.subscriber_id}`, query: { search: queryString } });
            },

            async clickSearch() {
                 await this.$router.push({ path: `/search/${this.getAuthUser.subscriber_id}`, query: { search: this.searchTerm } });
            },

            resetSearch() {
                this.searchTerm = '';
            },

            async querySearchAsync(queryString, cb) {
                const payload = {
                    subscriberId: this.getAuthUser.subscriber_id,
                    search: queryString,

                };
                this.searchResults = [];

                if (queryString) {
                    this.searchResults = await search.headerAutocompleteSearch(payload);
                }

                clearTimeout(this.timeout);
                this.timeout = cb(this.searchResults);
            },

            async handleSelect(item) {
				// 	@change="enterSearch"
                await this.$router.push({ path: `/search/${this.getAuthUser.subscriber_id}?search=${item.SKU}`});
            }
        }

    }
</script>
