export default [
    {
        path: '/scheduler',
        name: 'scheduler.index',
        component: () => import(/* webpackChunkName: "scheduler" */ '../views/Scheduler/Scheduler.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        },
    },
    {
        path: '/my-agenda',
        name: 'myAgenda.index',
        component: () => import(/* webpackChunkName: "myAgenda" */ '../views/Scheduler/MyAgenda.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        },
    },
]
