export default [
    {
        path: '/default-params',
        name: 'defaultParams.index',
        component: () => import(/* webpackChunkName: "defaultParams" */ '../views/DefaultParams/DefaultParams.vue'),
        meta: {
			layout: "default",
            auth: true,
            roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive'],
        },
    },
]
