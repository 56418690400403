import configs from '../../../api/configs.js';

export default {
    async loadConfigs({commit}) {
        const response = await configs.index();

        commit('setConfigs', response);
        return response;
    },

    async loadSiteTaxes({commit}, userId) {
        const response = await configs.loadSiteTaxes(userId);

        commit('setConfigs', response);
        return response;
    },

	async loadCurrentSiteTaxes({commit}, siteId) {
		const response = await configs.loadCurrentSiteTaxes(siteId);

		commit('setConfigs', response);
		return response;
	},

    async loadClientTaxes({commit}, userId) {
        const response = await configs.loadClientTaxes(userId);

        commit('setConfigs', response);
        return response;
    }
}
