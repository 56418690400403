export default [
	{
		path: '/serial:subscriber_id?',
		name: 'serial.index',
		component: () => import(/* webpackChunkName: "serial" */ '../views/Serial/Serial.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/add-serial',
		name: 'serial.store',
		component: () => import(/* webpackChunkName: "addSerial" */ '../views/Serial/AddSerial.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/serial/:id/edit',
		name: 'serial.edit',
		component: () => import(/* webpackChunkName: "editSerial" */ '../views/Serial/EditSerial.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		}
	}
]
