export default [
    {
        path: '/order:subscriber_id?',
        name: 'order.index',
        component: () => import(/* webpackChunkName: "order" */ '../views/Order/Order.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        },
    },
    {
        path: '/order/:id/edit',
        name: 'order-edit',
        component: () => import(/* webpackChunkName: "editOrder" */ '../views/Order/EditOrder.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        },
    },
    {
        path: '/orderPDF/:id',
        name: 'OrderPDF',
        component: () => import(/* webpackChunkName: "orderPDF" */ '../views/Order/OrderPDF.vue'),
        meta: {
            auth: true,
            layout: "empty"
        }
    },
    {
        path: '/my-orders',
        name: 'MyOrders',
        component: () => import(/* webpackChunkName: "myOrders" */ '../views/Order/MyOrders.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['client'],
        }
    },
    {
        path: '/view-order/:id',
        name: 'ViewOrder',
        component: () => import(/* webpackChunkName: "viewOrder" */ '../views/Order/ViewOrder.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['client'],
        },
    },
    {
        path: '/shipping-order/:id?',
        name: 'shippingOrder.index',
        component: () => import(/* webpackChunkName: "shippingOrder" */ '../views/Order/ShippingOrder.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        }
    },
    {
        path: '/packingOrderPDF/:id',
        name: 'PackingOrderPDF',
        component: () => import(/* webpackChunkName: "packingOrderPDF" */ '../views/Order/PackingOrderPDF.vue'),
        meta: {
            auth: true,
            layout: "empty"
        }
    },
]
