export default [
	{
		path: '/expense',
		name: 'expense.index',
		component: () => import(/* webpackChunkName: "expense" */ '../views/Expense/Expense.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'],
		},
	},
	{
		path: '/add-expense',
		name: 'expense.store',
		component: () => import(/* webpackChunkName: "addExpense" */ '../views/Expense/AddExpense.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'],
		},
	},
	{
		path: '/expense/:id/edit',
		name: 'expense.edit',
		component: () => import(/* webpackChunkName: "editExpense" */ '../views/Expense/EditExpense.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'],
		},
	},
	{
		path: '/expense-payment',
		name: 'expensePayment.index',
		component: () => import(/* webpackChunkName: "expensePayment" */ '../views/Expense/ExpensePayment.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'],
		},
	},
	{
		path: '/expense-payment/:id/view',
		name: 'expensePayment.view',
		component: () => import(/* webpackChunkName: "viewExpensePayment" */ '../views/Expense/ViewExpensePayment.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'],
		},
	},
	{
		path: '/credit-note',
		name: 'creditNote.index',
		component: () => import(/* webpackChunkName: "credit-note" */ '../views/Expense/CreditNote.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'],
		},
	},
	{
		path: '/add-credit-note',
		name: 'creditNote.store',
		component: () => import(/* webpackChunkName: "addCreditNote" */ '../views/Expense/AddCreditNote.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'],
		},
	},
	{
		path: '/credit-note/:id/edit',
		name: 'creditNote.edit',
		component: () => import(/* webpackChunkName: "editCreditNote" */ '../views/Expense/EditCreditNote.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'],
		},
	},
	{
		path: '/operational-expense',
		name: 'operationalExpense.index',
		component: () => import(/* webpackChunkName: "operationalExpense" */ '../views/Expense/OperationalExpense.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'],
		},
	},
	{
		path: '/add-operational-expense',
		name: 'operationalExpense.store',
		component: () => import(/* webpackChunkName: "addOperationalExpense" */ '../views/Expense/AddOperationalExpense.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'],
		},
	},
	{
		path: '/operational-expense/:id/edit',
		name: 'operationalExpense.edit',
		component: () => import(/* webpackChunkName: "editOperationalExpense" */ '../views/Expense/EditOperationalExpense.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'],
		},
	},
]
