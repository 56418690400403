export default [
    {
        path: '/tax',
        name: 'tax.index',
        component: () => import(/* webpackChunkName: "tax" */ '../views/Tax/Tax.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration'],
        },
    },
    {
        path: '/add-tax',
        name: 'tax.store',
        component: () => import(/* webpackChunkName: "addTax" */ '../views/Tax/AddTax.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration'],
        }
    },
    {
        path: '/tax/:id/edit',
        name: 'tax.edit',
        component: () => import(/* webpackChunkName: "editTax" */ '../views/Tax/EditTax.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration'],
        }
    },
]
