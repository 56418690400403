export default [
	{
		path: '/bank',
		name: 'bank.index',
		component: () => import(/* webpackChunkName: "bank" */ '../views/Bank/Bank.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration'],
		},
	},
	{
		path: '/add-bank',
		name: 'bank.store',
		component: () => import(/* webpackChunkName: "addBank" */ '../views/Bank/AddBank.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration'],
		}
	},
	{
		path: '/bank/:id/edit',
		name: 'bank.edit',
		component: () => import(/* webpackChunkName: "editBank" */ '../views/Bank/EditBank.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration'],
		}
	},
]
