export default [
	{
		path: '/project:subscriber_id?',
		name: 'project.index',
		component: () => import(/* webpackChunkName: "project" */ '../views/Project/Project.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
]
