<template>
    <el-tooltip
        :placement="placement"
        :effect="effect"
        :show-after="delay"
    >
        <template #content>
            <slot name="content" />
        </template>
        <slot />
    </el-tooltip>
</template>

<script>
    export default {
        name: 'AppTooltip',

        props: {
            placement: {
                type: String,
                default: 'top',
            },
            effect: {
                type: String,
                default: "dark",
            },
            delay: {
                type: Number,
                default: 0,
            },
        }
    }
</script>
