import axios from 'axios';

let baseApi = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL
});

baseApi.interceptors.response.use(function (response) {
	// Do something with response data
	return response;
}, function (error) {
	// Do something with response error
	if (error.response.status === 401) {
		localStorage.removeItem('access_token');
		localStorage.removeItem('id');
		window.location = '/login'
	}

	return Promise.reject(error);
});

let api = function() {
    let token = localStorage.getItem('access_token');

    if (token) {
        baseApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    return baseApi;
}

export default api;
