import { VueEcharts } from 'vue3-echarts/index.js';
import { use } from "echarts/core"
import { SVGRenderer } from "echarts/renderers";
import {
	BarChart,
	PieChart
} from 'echarts/charts'
import {
	TitleComponent,
	TooltipComponent,
	GridComponent,
	LegendComponent,
	ToolboxComponent,
} from "echarts/components";

const bootEChart = app => {
	app.component('v-chart', VueEcharts);
	use([BarChart, PieChart, TitleComponent, TooltipComponent, GridComponent, LegendComponent, ToolboxComponent, SVGRenderer]);
}

export { bootEChart };
