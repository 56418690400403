import api from '../plugins/api.js'

export default {
    async index(subscriberId) {
        const response = await api().get(`${process.env.VUE_APP_BASE_URL}/v1/default-params/${subscriberId}`);

        return response.data.data;
    },

    async update(subscriberId, payload = {}) {
        const response = await api().put(`${process.env.VUE_APP_BASE_URL}/v1/default-params/${subscriberId}`, payload);

        return response.data.data;
    },

    async showServiceTicketStatus(subscriberId) {
        const response = await api().get(`${process.env.VUE_APP_BASE_URL}/v1/service-ticket-status/${subscriberId}`);

        return response.data.data;
    },

    async updateServiceTicketStatus(subscriberId, payload = {}) {
        const response = await api().put(`${process.env.VUE_APP_BASE_URL}/v1/service-ticket-status/${subscriberId}`, payload);

        return response.data.data;
    },

    async loadCacheScheduleUsers(payload ={}) {
        const response = await api().get(`${process.env.VUE_APP_BASE_URL}/v1/load-cache-schedule-users`, {
            params: payload
        });

        return response.data.data;
    },

    async updateCacheScheduleUsers(payload ={}) {
        const response = await api().put(`${process.env.VUE_APP_BASE_URL}/v1/update-cache-schedule-users`, payload);

        return response.data.data;
    },

	async getServiceRate(subscriberId) {
		const response = await api().get(`${process.env.VUE_APP_BASE_URL}/v1/service-rate/${subscriberId}/edit`);

		return response.data.data;
	},

	async updateServiceRate(subscriberId, payload = {}) {
		const response = await api().put(`${process.env.VUE_APP_BASE_URL}/v1/service-rate/${subscriberId}`, payload);

		return response.data.data;
	},

}
