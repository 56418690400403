export default [
    {
        path: '/payment-term',
        name: 'paymentTerm.index',
        component: () => import(/* webpackChunkName: "paymentTerm" */ '../views/PaymentTerm/PaymentTerm.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration'],
        },
    },
    {
        path: '/add-payment-term',
        name: 'paymentTerm.store',
        component: () => import(/* webpackChunkName: "addPaymentTerm" */ '../views/PaymentTerm/AddPaymentTerm.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration'],
        }
    },
    {
        path: '/payment-term/:id/edit',
        name: 'paymentTerm.edit',
        component: () => import(/* webpackChunkName: "editPaymentTerm" */ '../views/PaymentTerm/EditPaymentTerm.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration'],
        }
    }
]
