import store from '../store'
import { addHours, differenceInDays, format, formatISO, parseISO } from 'date-fns'
import { getTimezoneOffset, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

// Compare two objects
export function isEqual(object1, object2) {
	return Object.entries(object1).toString() === Object.entries(object2).toString();
}

// Capitalizie first letters
export function ucFirst(strText) {
	return strText.charAt(0).toUpperCase() + strText.substring(1);
}

export function capitalizeTheFirstLetterOfEachWord(strText) {
	let separateWord = strText.toLowerCase().split(' ');
	for (let i = 0; i < separateWord.length; i++) {
		separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
			separateWord[i].substring(1);
	}
	return separateWord.join(' ');
}

// Get first character of phrase and join together
export function strInitial(string) {
    return string.split(' ').map(word => word.charAt(0)).join('').toUpperCase();
}

// Sort array by key. Return a new instance.
export function dynamicSort(array, key, asc = true) {
	const sortedArray = array.concat().sort((a, b) => {
		let valueA = a;
		let valueB = b;

		if (key) {
			valueA = a[key];
			valueB = b[key];
		}

		return valueA.localeCompare(valueB);
	});

	return asc ? sortedArray : sortedArray.reverse();
}

// Check date is in range between Start, End
export function dateIsInRange(startDate, endDate) {
	const start = new Date(startDate).getTime();
	const end = new Date(endDate).getTime();
	const today = new Date(new Date().getFullYear(),new Date().getMonth() , new Date().getDate()).getTime();

	return today >= start && today <= end;
}

// Convert time to decimal (01:30 => 1.5)
export function timeToDecimal(time) {
	if (time) {
		const [h, m] = time.split(':');
		return (+h + (+m/60)).toFixed(2);
	}

	return 0;
}

//Get (greeting) title object
export function getTitle(id) {
	const titles = store.getters.getConfigs.titles;

	return titles.find(title => {
		return title.id === id
	});
}

// Generate random string
export function generateRandomString(length) {
	return Math.random().toString(20).substr(2, length);
}

export function objectIsEmpty(object) {
	for(const key in object) {
		if (Object.prototype.hasOwnProperty.call(object, key))
			return false;
	}
	return true;
}

// Check object keys are all empties
export function objectKeysIsEmpty(object) {
	return Object.keys(object).length == 0;
}

// Subscriber (based on user's -> site -> taxes) determine taxes to collect - consolidate to contain only unique tax level
export function collectTaxRules(products, handlingFee, shippingFee, invoice = false) {
	// Taxes charged by subscriber's site
	const taxesCollected = {};
	const subscriberTaxes = store.getters.getConfigs.siteTaxes;

	subscriberTaxes.map( item => {
		if (item.countryApply === true){
			if (! Object.prototype.hasOwnProperty.call(taxesCollected, 'countryApply')) {
				taxesCollected.countryApply = true;
				taxesCollected.countryAbbrev_en = item.countryAbbrev_en;
				taxesCollected.countryAbbrev_fr = item.countryAbbrev_fr;
				taxesCollected.countryAbbrev_es = item.countryAbbrev_es;
				taxesCollected.countryRate = item.countryRate;
			}
		}

		if (item.provinceApply === true){
			if (! Object.prototype.hasOwnProperty.call(taxesCollected, 'provinceApply')) {
				taxesCollected.provinceApply = true;
				taxesCollected.provinceAbbrev_en = item.provinceAbbrev_en;
				taxesCollected.provinceAbbrev_fr = item.provinceAbbrev_fr;
				taxesCollected.provinceAbbrev_es = item.provinceAbbrev_es;
				taxesCollected.provinceRate = item.provinceRate;
			}
		}

		if (item.cityApply === true){
			if (! Object.prototype.hasOwnProperty.call(taxesCollected, 'cityApply')) {
				taxesCollected.cityApply = true;
				taxesCollected.cityAbbrev_en = item.cityAbbrev_en;
				taxesCollected.cityAbbrev_fr = item.cityAbbrev_fr;
				taxesCollected.cityAbbrev_es = item.cityAbbrev_es;
				taxesCollected.cityRate = item.cityRate;
			}
		}

		if (item.specialApply === true){
			if (! Object.prototype.hasOwnProperty.call(taxesCollected, 'specialApply')) {
				taxesCollected.specialApply = true;
				taxesCollected.specialAbbrev_en = item.specialAbbrev_en;
				taxesCollected.specialAbbrev_fr = item.specialAbbrev_fr;
				taxesCollected.specialAbbrev_es = item.specialAbbrev_es;
				taxesCollected.specialRate = item.specialRate;
			}
		}

		if (item.otherApply === true){
			if (! Object.prototype.hasOwnProperty.call(taxesCollected, 'otherApply')) {
				taxesCollected.otherApply = true;
				taxesCollected.otherAbbrev_en = item.otherAbbrev_en;
				taxesCollected.otherAbbrev_fr = item.otherAbbrev_fr;
				taxesCollected.otherAbbrev_es = item.otherAbbrev_es;
				taxesCollected.otherRate = item.otherRate;
				taxesCollected.countryAbbrev_en = item.countryAbbrev_en;
				taxesCollected.countryAbbrev_fr = item.countryAbbrev_fr;
				taxesCollected.countryAbbrev_es = item.countryAbbrev_es;
				taxesCollected.countryRate = item.countryRate;
				taxesCollected.provinceAbbrev_en = item.provinceAbbrev_en;
				taxesCollected.provinceAbbrev_fr = item.provinceAbbrev_fr;
				taxesCollected.provinceAbbrev_es = item.provinceAbbrev_es;
				taxesCollected.provinceRate = item.provinceRate;
			}
		}
	});

	// Taxes due by client
	const taxesDue = {};
	const clientTax = store.getters.getConfigs.clientTax;

	if (clientTax) {
		if (clientTax.countryApply === true) {
			if (! Object.prototype.hasOwnProperty.call(taxesDue, 'countryApply')) {
				taxesDue.countryApply = true;
				taxesDue.countryAbbrev_en = clientTax.countryAbbrev_en;
				taxesDue.countryAbbrev_fr = clientTax.countryAbbrev_fr;
				taxesDue.countryAbbrev_es = clientTax.countryAbbrev_es;
				taxesDue.countryRate = clientTax.countryRate;
			}
		}

		if (clientTax.provinceApply === true){
			if (! Object.prototype.hasOwnProperty.call(taxesDue, 'provinceApply')) {
				taxesDue.provinceApply = true;
				taxesDue.provinceAbbrev_en = clientTax.provinceAbbrev_en;
				taxesDue.provinceAbbrev_fr = clientTax.provinceAbbrev_fr;
				taxesDue.provinceAbbrev_es = clientTax.provinceAbbrev_es;
				taxesDue.provinceRate = clientTax.provinceRate;
			}
		}

		if (clientTax.cityApply === true){
			if (! Object.prototype.hasOwnProperty.call(taxesDue, 'cityApply')) {
				taxesDue.cityApply = true;
				taxesDue.cityAbbrev_en = clientTax.cityAbbrev_en;
				taxesDue.cityAbbrev_fr = clientTax.cityAbbrev_fr;
				taxesDue.cityAbbrev_es = clientTax.cityAbbrev_es;
				taxesDue.cityRate = clientTax.cityRate;
			}
		}

		if (clientTax.specialApply === true){
			if (! Object.prototype.hasOwnProperty.call(taxesDue, 'specialApply')) {
				taxesDue.specialApply = true;
				taxesDue.specialAbbrev_en = clientTax.specialAbbrev_en;
				taxesDue.specialAbbrev_fr = clientTax.specialAbbrev_fr;
				taxesDue.specialAbbrev_es = clientTax.specialAbbrev_es;
				taxesDue.specialRate = clientTax.specialRate;
			}
		}

		if (clientTax.otherApply === true){
			if (! Object.prototype.hasOwnProperty.call(taxesDue, 'otherApply')) {
				taxesDue.otherApply = true;
				taxesDue.otherAbbrev_en = clientTax.otherAbbrev_en;
				taxesDue.otherAbbrev_fr = clientTax.otherAbbrev_fr;
				taxesDue.otherAbbrev_es = clientTax.otherAbbrev_es;
				taxesDue.otherRate = clientTax.otherRate;
				taxesDue.countryAbbrev_en = clientTax.countryAbbrev_en;
				taxesDue.countryAbbrev_fr = clientTax.countryAbbrev_fr;
				taxesDue.countryAbbrev_es = clientTax.countryAbbrev_es;
				taxesDue.countryRate = clientTax.countryRate;
				taxesDue.provinceAbbrev_en = clientTax.provinceAbbrev_en;
				taxesDue.provinceAbbrev_fr = clientTax.provinceAbbrev_fr;
				taxesDue.provinceAbbrev_es = clientTax.provinceAbbrev_es;
				taxesDue.provinceRate = clientTax.provinceRate;
			}
		}
	}

	const site = store.getters.getCurrent.site;
	const client = store.getters.getCurrent.client;

	// Taxes applicable from the 2 taxes above
	const taxesCharged = {};

	const siteCountry = site.country_id;
	const clientCountry = client.shippingCountry_id ? client.shippingCountry_id : client.generalCountry_id;

	// No tax when ship to different country
	if (siteCountry !== clientCountry) {
		return null;
	}

	// No tax when client is tax exempted
	if (client.taxExempt && client.taxExemptionNumber) {
		return null;
	}

	// No tax when subscriber does not collect taxes or client does not have taxdues
	if (objectIsEmpty(taxesCollected) || objectIsEmpty(taxesDue) ) {
		return null;
	}

	if (taxesDue.countryApply && taxesCollected.countryApply) {
		if (! Object.prototype.hasOwnProperty.call(taxesCharged, 'countryApply')) {
			taxesCharged.countryApply = true;
			taxesCharged.countryAbbrev_en = taxesDue.countryAbbrev_en;
			taxesCharged.countryAbbrev_fr = taxesDue.countryAbbrev_fr;
			taxesCharged.countryAbbrev_es = taxesDue.countryAbbrev_es;
			taxesCharged.countryRate = taxesDue.countryRate;
		}
	}

	if (taxesDue.provinceApply && taxesCollected.provinceApply) {
		if (! Object.prototype.hasOwnProperty.call(taxesCharged, 'provinceApply')) {
			taxesCharged.provinceApply = true;
			taxesCharged.provinceAbbrev_en = taxesDue.provinceAbbrev_en;
			taxesCharged.provinceAbbrev_fr = taxesDue.provinceAbbrev_fr;
			taxesCharged.provinceAbbrev_es = taxesDue.provinceAbbrev_es;
			taxesCharged.provinceRate = taxesDue.provinceRate;
		}
	}

	if (taxesDue.otherApply && taxesCollected.otherApply) {
		if (! Object.prototype.hasOwnProperty.call(taxesCharged, 'otherApply')) {
			taxesCharged.otherApply = true;
			taxesCharged.otherAbbrev_en = taxesDue.otherAbbrev_en;
			taxesCharged.otherAbbrev_fr = taxesDue.otherAbbrev_fr;
			taxesCharged.otherAbbrev_es = taxesDue.otherAbbrev_es;
			taxesCharged.otherRate = taxesDue.otherRate;
			taxesCharged.countryAbbrev_en = taxesDue.countryAbbrev_en;
			taxesCharged.countryAbbrev_fr = taxesDue.countryAbbrev_fr;
			taxesCharged.countryAbbrev_es = taxesDue.countryAbbrev_es;
			taxesCharged.countryRate = taxesDue.countryRate;
			taxesCharged.provinceAbbrev_en = taxesDue.provinceAbbrev_en;
			taxesCharged.provinceAbbrev_fr = taxesDue.provinceAbbrev_fr;
			taxesCharged.provinceAbbrev_es = taxesDue.provinceAbbrev_es;
			taxesCharged.provinceRate = taxesDue.provinceRate;
		}
	}

	// Site (country + provincial QC) charges taxes to client with other
	if (taxesDue.otherApply && taxesCollected.countryApply && taxesCollected.provinceApply) {
		taxesCharged.otherApply = true;
		taxesCharged.otherAbbrev_en = taxesDue.otherAbbrev_en;
		taxesCharged.otherAbbrev_fr = taxesDue.otherAbbrev_fr;
		taxesCharged.otherAbbrev_es = taxesDue.otherAbbrev_es;
		taxesCharged.otherRate = taxesDue.otherRate;
		taxesCharged.countryAbbrev_en = taxesDue.countryAbbrev_en;
		taxesCharged.countryAbbrev_fr = taxesDue.countryAbbrev_fr;
		taxesCharged.countryAbbrev_es = taxesDue.countryAbbrev_es;
		taxesCharged.countryRate = taxesDue.countryRate;
		taxesCharged.provinceAbbrev_en = taxesDue.provinceAbbrev_en;
		taxesCharged.provinceAbbrev_fr = taxesDue.provinceAbbrev_fr;
		taxesCharged.provinceAbbrev_es = taxesDue.provinceAbbrev_es;
		taxesCharged.provinceRate = taxesDue.provinceRate;
	}

	// Site other charges tax to client (country + provincial, QC)
	if (taxesDue.countryApply && taxesCollected.otherApply) {
		taxesCharged.countryApply = true;
		taxesCharged.countryAbbrev_en = taxesDue.countryAbbrev_en;
		taxesCharged.countryAbbrev_fr = taxesDue.countryAbbrev_fr;
		taxesCharged.countryAbbrev_es = taxesDue.countryAbbrev_es;
		taxesCharged.countryRate = taxesDue.countryRate;
	}

	if (taxesDue.provinceApply && taxesCollected.otherApply) {
		taxesCharged.provinceApply = true;
		taxesCharged.provinceAbbrev_en = taxesDue.provinceAbbrev_en;
		taxesCharged.provinceAbbrev_fr = taxesDue.provinceAbbrev_fr;
		taxesCharged.provinceAbbrev_es = taxesDue.provinceAbbrev_es;
		taxesCharged.provinceRate = taxesDue.provinceRate;
	}

	if (taxesDue.cityApply && taxesCollected.cityApply) {
		if (! Object.prototype.hasOwnProperty.call(taxesCharged, 'cityApply')) {
			taxesCharged.cityApply = true;
			taxesCharged.cityAbbrev_en = taxesDue.cityAbbrev_en;
			taxesCharged.cityAbbrev_fr = taxesDue.cityAbbrev_fr;
			taxesCharged.cityAbbrev_es = taxesDue.cityAbbrev_es;
			taxesCharged.cityRate = taxesDue.cityRate;
		}
	}

	if (taxesDue.specialApply && taxesCollected.specialApply) {
		if (! Object.prototype.hasOwnProperty.call(taxesCharged, 'specialApply')) {
			taxesCharged.specialApply = true;
			taxesCharged.specialAbbrev_en = taxesCollected.specialAbbrev_en;
			taxesCharged.specialAbbrev_fr = taxesCollected.specialAbbrev_fr;
			taxesCharged.specialAbbrev_es = taxesCollected.specialAbbrev_es;
			taxesCharged.specialRate = taxesCollected.specialRate;
		}
	}

	let taxesAccumulated = {
		countryApply: false,
		provinceApply: false,
		cityApply: false,
		specialApply: false,
		otherApply: false,
	};
	let countryTax = 0;
	let provinceTax = 0;
	let cityTax = 0;
	let specialTax = 0;
	let otherTax = 0;
	let ecoTax = 0;

	if (invoice) {
		products.forEach(product => {
			if (product.countryApply && taxesCharged.countryApply) {
				taxesAccumulated.countryApply = true;
				taxesAccumulated.countryAbbrev_en = taxesCharged.countryAbbrev_en;
				taxesAccumulated.countryAbbrev_fr = taxesCharged.countryAbbrev_fr;
				taxesAccumulated.countryAbbrev_es = taxesCharged.countryAbbrev_es;
				countryTax = eval(countryTax) + eval((Math.round(Number(product.invoicing) * Number(product.price) * Number(taxesCharged.countryRate) / 100 * 100) / 100).toFixed(2));
			}
		})

		products.forEach(product => {
			if (product.provinceApply && taxesCharged.provinceApply) {
				taxesAccumulated.provinceApply = true;
				taxesAccumulated.provinceAbbrev_en = taxesCharged.provinceAbbrev_en;
				taxesAccumulated.provinceAbbrev_fr = taxesCharged.provinceAbbrev_fr;
				taxesAccumulated.provinceAbbrev_es = taxesCharged.provinceAbbrev_es;
				provinceTax = eval(provinceTax) + eval((Math.round(Number(product.invoicing) * Number(product.price) * Number(taxesCharged.provinceRate) / 100 * 100) / 100).toFixed(2));
			}
		})

		products.forEach(product => {
			if (product.countryApply && product.provinceApply && taxesCharged.otherApply) {
				taxesAccumulated.otherApply = true;
				taxesAccumulated.otherAbbrev_en = taxesCharged.otherAbbrev_en;
				taxesAccumulated.otherAbbrev_fr = taxesCharged.otherAbbrev_fr;
				taxesAccumulated.otherAbbrev_es = taxesCharged.otherAbbrev_es;
				otherTax = eval(otherTax) + eval((Math.round(Number(product.invoicing) * Number(product.price) * Number(taxesCharged.otherRate) / 100 * 100) / 100).toFixed(2));
			}
		})

		products.forEach(product => {
			if (taxesCharged.cityApply) {
				taxesAccumulated.cityApply = true;
				taxesAccumulated.cityAbbrev_en = taxesCharged.cityAbbrev_en;
				taxesAccumulated.cityAbbrev_fr = taxesCharged.cityAbbrev_fr;
				taxesAccumulated.cityAbbrev_es = taxesCharged.cityAbbrev_es;
				cityTax = eval(cityTax) + eval((Math.round(Number(product.invoicing) * Number(product.price) * Number(taxesCharged.cityRate) / 100 * 100) / 100).toFixed(2));
			}
		})

		products.forEach(product => {
			if (taxesCharged.specialApply) {
				taxesAccumulated.specialApply = true;
				taxesAccumulated.specialAbbrev_en = taxesCharged.specialAbbrev_en;
				taxesAccumulated.specialAbbrev_fr = taxesCharged.specialAbbrev_fr;
				taxesAccumulated.specialAbbrev_es = taxesCharged.specialAbbrev_es;
				specialTax = eval(specialTax) + eval((Math.round(Number(product.invoicing) * Number(product.price) * Number(taxesCharged.specialRate) / 100 * 100) / 100).toFixed(2));
			}
		})

		products.forEach(product => {
			if (product.countryApply && !product.provinceApply && taxesCharged.otherApply) {
				taxesAccumulated.countryApply = true;
				taxesAccumulated.countryAbbrev_en = taxesCharged.countryAbbrev_en;
				taxesAccumulated.countryAbbrev_fr = taxesCharged.countryAbbrev_fr;
				taxesAccumulated.countryAbbrev_es = taxesCharged.countryAbbrev_es;
				countryTax = eval(countryTax) + eval((Math.round(Number(product.invoicing) * Number(product.price) * Number(taxesCharged.countryRate) / 100 * 100) / 100).toFixed(2));

			}
		})

		products.forEach(product => {
			if (!product.countryApply && product.provinceApply && taxesCharged.otherApply) {
				taxesAccumulated.provinceApply = true;
				taxesAccumulated.provinceAbbrev_en = taxesCharged.provinceAbbrev_en;
				taxesAccumulated.provinceAbbrev_fr = taxesCharged.provinceAbbrev_fr;
				taxesAccumulated.provinceAbbrev_es = taxesCharged.provinceAbbrev_es;
				provinceTax = eval(provinceTax) + eval((Math.round(Number(product.invoicing) * Number(product.price) * Number(taxesCharged.provinceRate) / 100 * 100) / 100).toFixed(2));
			}
		})

		// For country with one tax (use other), product with no tax, set country and province tax to false
		products.forEach(product => {
			if (!product.countryApply && !product.provinceApply && taxesCharged.otherApply) {
				taxesAccumulated.otherApply = true;
				taxesAccumulated.otherAbbrev_en = taxesCharged.otherAbbrev_en;
				taxesAccumulated.otherAbbrev_fr = taxesCharged.otherAbbrev_fr;
				taxesAccumulated.otherAbbrev_es = taxesCharged.otherAbbrev_es;
				otherTax = eval(otherTax) + eval((Math.round(0 / 100 * 100) / 100).toFixed(2));
			}
		})

		products.forEach(product => {
			if (product.ecofee_id) {
				const ecoFee = store.getters.getCurrent.defaultParams.ecofee.filter(item => item.id === product.ecofee_id);
				ecoTax = eval(ecoTax) + eval((Math.round(Number(product.invoicing) * Number(ecoFee[0].fee) * 100 ) / 100 ).toFixed(2));
			}
		})
	} else {
		products.forEach(product => {
			if (product.countryApply && taxesCharged.countryApply) {
				taxesAccumulated.countryApply = true;
				taxesAccumulated.countryAbbrev_en = taxesCharged.countryAbbrev_en;
				taxesAccumulated.countryAbbrev_fr = taxesCharged.countryAbbrev_fr;
				taxesAccumulated.countryAbbrev_es = taxesCharged.countryAbbrev_es;
				countryTax = eval(countryTax) + eval((Math.round(Number(product.quantity) * Number(product.price) * Number(taxesCharged.countryRate) / 100 * 100) / 100).toFixed(2));
			}
		})

		products.forEach(product => {
			if (product.provinceApply && taxesCharged.provinceApply) {
				taxesAccumulated.provinceApply = true;
				taxesAccumulated.provinceAbbrev_en = taxesCharged.provinceAbbrev_en;
				taxesAccumulated.provinceAbbrev_fr = taxesCharged.provinceAbbrev_fr;
				taxesAccumulated.provinceAbbrev_es = taxesCharged.provinceAbbrev_es;
				provinceTax = eval(provinceTax) + eval((Math.round(Number(product.quantity) * Number(product.price) * Number(taxesCharged.provinceRate) / 100 * 100) / 100).toFixed(2));
			}
		})

		products.forEach(product => {
			if (product.countryApply && product.provinceApply && taxesCharged.otherApply) {
				taxesAccumulated.otherApply = true;
				taxesAccumulated.otherAbbrev_en = taxesCharged.otherAbbrev_en;
				taxesAccumulated.otherAbbrev_fr = taxesCharged.otherAbbrev_fr;
				taxesAccumulated.otherAbbrev_es = taxesCharged.otherAbbrev_es;
				otherTax = eval(otherTax) + eval((Math.round(Number(product.quantity) * Number(product.price) * Number(taxesCharged.otherRate) / 100 * 100) / 100).toFixed(2));
			}
		})

		products.forEach(product => {
			if (taxesCharged.cityApply) {
				taxesAccumulated.cityApply = true;
				taxesAccumulated.cityAbbrev_en = taxesCharged.cityAbbrev_en;
				taxesAccumulated.cityAbbrev_fr = taxesCharged.cityAbbrev_fr;
				taxesAccumulated.cityAbbrev_es = taxesCharged.cityAbbrev_es;
				cityTax = eval(cityTax) + eval((Math.round(Number(product.quantity) * Number(product.price) * Number(taxesCharged.cityRate) / 100 * 100) / 100).toFixed(2));
			}
		})

		products.forEach(product => {
			if (taxesCharged.specialApply) {
				taxesAccumulated.specialApply = true;
				taxesAccumulated.specialAbbrev_en = taxesCharged.specialAbbrev_en;
				taxesAccumulated.specialAbbrev_fr = taxesCharged.specialAbbrev_fr;
				taxesAccumulated.specialAbbrev_es = taxesCharged.specialAbbrev_es;
				specialTax = eval(specialTax) + eval((Math.round(Number(product.quantity) * Number(product.price) * Number(taxesCharged.specialRate) / 100 * 100) / 100).toFixed(2));
			}
		})

		products.forEach(product => {
			if (product.countryApply && !product.provinceApply && taxesCharged.otherApply) {
				taxesAccumulated.countryApply = true;
				taxesAccumulated.countryAbbrev_en = taxesCharged.countryAbbrev_en;
				taxesAccumulated.countryAbbrev_fr = taxesCharged.countryAbbrev_fr;
				taxesAccumulated.countryAbbrev_es = taxesCharged.countryAbbrev_es;
				countryTax = eval(countryTax) + eval((Math.round(Number(product.quantity) * Number(product.price) * Number(taxesCharged.countryRate) / 100 * 100) / 100).toFixed(2));

			}
		})

		products.forEach(product => {
			if (!product.countryApply && product.provinceApply && taxesCharged.otherApply) {
				taxesAccumulated.provinceApply = true;
				taxesAccumulated.provinceAbbrev_en = taxesCharged.provinceAbbrev_en;
				taxesAccumulated.provinceAbbrev_fr = taxesCharged.provinceAbbrev_fr;
				taxesAccumulated.provinceAbbrev_es = taxesCharged.provinceAbbrev_es;
				provinceTax = eval(provinceTax) + eval((Math.round(Number(product.quantity) * Number(product.price) * Number(taxesCharged.provinceRate) / 100 * 100) / 100).toFixed(2));
			}
		})

		// For country with one tax (use other), product with no tax, set country and province tax to false
		products.forEach(product => {
			if (!product.countryApply && !product.provinceApply && taxesCharged.otherApply) {
				taxesAccumulated.otherApply = true;
				taxesAccumulated.otherAbbrev_en = taxesCharged.otherAbbrev_en;
				taxesAccumulated.otherAbbrev_fr = taxesCharged.otherAbbrev_fr;
				taxesAccumulated.otherAbbrev_es = taxesCharged.otherAbbrev_es;
				otherTax = eval(otherTax) + eval((Math.round(0 / 100 * 100) / 100).toFixed(2));
			}
		})

		products.forEach(product => {
			if (product.ecofee_id) {
				const ecoFee = store.getters.getCurrent.defaultParams.ecofee.filter(item => item.id === product.ecofee_id);
				ecoTax = eval(ecoTax) + eval((Math.round(Number(product.quantity) * Number(ecoFee[0].fee) * 100 ) / 100 ).toFixed(2));
			}
		})
	}


	if (handlingFee) {
		countryTax = eval(countryTax) + eval( (Math.round(Number(handlingFee) * Number(taxesCharged.countryRate) / 100 * 100) / 100).toFixed(2));
		provinceTax = eval(provinceTax) + eval( (Math.round(Number(handlingFee) * Number(taxesCharged.provinceRate) / 100 * 100) / 100).toFixed(2));
	}

	if (shippingFee) {
		countryTax = eval(countryTax) + eval( (Math.round(Number(shippingFee) * Number(taxesCharged.countryRate) / 100 * 100) / 100).toFixed(2));
		provinceTax = eval(provinceTax) + eval( (Math.round(Number(shippingFee) * Number(taxesCharged.provinceRate) / 100 * 100) / 100).toFixed(2));
	}

	taxesAccumulated.countryTax = (Math.round(countryTax * 100 ) /100).toFixed(2);
	taxesAccumulated.provinceTax = (Math.round(provinceTax * 100 ) /100).toFixed(2);
	taxesAccumulated.cityTax = (Math.round(cityTax * 100 ) /100).toFixed(2);
	taxesAccumulated.specialTax = (Math.round(specialTax * 100 ) /100).toFixed(2);
	taxesAccumulated.otherTax = (Math.round(otherTax * 100 ) /100).toFixed(2);
	taxesAccumulated.ecoTax = (Math.round(ecoTax * 100 ) /100).toFixed(2);

	return taxesAccumulated;
}

export function shortDateTime(datetime) {
	if (datetime) {
		return format(new Date(datetime), "yyyy-MM-dd HH:mm:ss");
	}

	return '';
}

export function shortDate(datetime) {
	if (datetime) {
		return format(new Date(datetime), "yyyy-MM-dd");
	}

	return '';
}

// Get php datetime UTC from back end (timestamp format) and convert to zoned time
export function phpUTCToZonedShortDateTime(datetime, timezone) {
	if (typeof datetime == 'undefined') {
		return '';
	}
	const formatDate = new Date(`'${datetime} UTC'`).toISOString();
	const pattern = "yyyy-MM-dd HH:mm:ss";
	return format(utcToZonedTime(formatDate, timezone), pattern);
}

// Convert zoned time from browser to UTC, output like this 2020-10-09T11:29:52-04:00
export function zonedToUTCShortDateTime(datetime, timezone) {
	return formatISO(zonedTimeToUtc(datetime, timezone));
}

// Convert UTC to zoned time from DB to browser, also used with DB's created_at to output to browser
// Input like this 2020-10-09T11:29:52-04:00 or ISO format 2021-07-02T13:17:08.000Z
// Output like this 2020-10-09 13:33:19
export function UTCToZonedShortDateTime(datetime, timezone) {
	const pattern = "yyyy-MM-dd HH:mm:ss";
	return format(utcToZonedTime(datetime, timezone), pattern);
}

// Calculate difference in days (from a given date and timezone versus now)
export function calculateDiffInDaysToNow(datetime, timezone) {
	return differenceInDays(zonedTimeToUtc(parseISO(datetime), timezone), zonedTimeToUtc(Date.now(), timezone));
}

// Convert database datetime string (2023-01-29 17:53:47), registered in UTC to user timezone
export function calculateUTCStringToTimezoneOffset(datetimeUTC, timezone) {
	const pattern = "yyyy-MM-dd HH:mm:ss";
	return format(addHours(new Date(datetimeUTC), getTimezoneOffset(timezone)/3600000), pattern);
}

// Convert minutes to hours and minutes (format from 00:00 - 23:59)
export function toHoursAndMinutes(timeinMinutes) {
	if (timeinMinutes === 1440) {
		return `00:00`;
	} else if (timeinMinutes < 1440) {
		const hours = Math.floor(timeinMinutes / 60);
		const minutes = timeinMinutes % 60;

		const formattedHours = hours < 10 ? `0${hours}` : hours;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

		return `${formattedHours}:${formattedMinutes}`;
	} else {
		const remaining = timeinMinutes % 1440;

		const hours = Math.floor(remaining / 60);
		const minutes = remaining % 60;

		const formattedHours = hours < 10 ? `0${hours}` : hours;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

		return `${formattedHours}:${formattedMinutes}`;
	}
}
