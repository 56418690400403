export default [
    {
        path: '/supplier',
        name: 'supplier.index',
        component: () => import(/* webpackChunkName: "supplier" */ '../views/Supplier/Supplier.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        },
    },
    {
        path: '/add-supplier',
        name: 'supplier.store',
        component: () => import(/* webpackChunkName: "addSupplier" */ '../views/Supplier/AddSupplier.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        },
    },
    {
        path: '/supplier/:id/edit',
        name: 'supplier.edit',
        component: () => import(/* webpackChunkName: "editSupplier" */ '../views/Supplier/EditSupplier.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        }
    },
    {
        path: '/purchase-order',
        name: 'purchaseOrder.index',
        component: () => import(/* webpackChunkName: "purchaseOrder" */ '../views/Supplier/PurchaseOrder.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        }
    },
    {
        path: '/purchase-order/:id/edit',
        name: 'purchaseOrder.edit',
        component: () => import(/* webpackChunkName: "editPurchaseOrder" */ '../views/Supplier/EditPurchaseOrder.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        }
    },
    {
        path: '/purchasePDF/:id',
        name: 'PurchasePDF',
        component: () => import(/* webpackChunkName: "purchasePDF" */ '../views/Supplier/PurchasePDF.vue'),
        meta: {
            auth: true,
            layout: "empty"
        }
    },
    {
        path: '/reception',
        name: 'reception.index',
        component: () => import(/* webpackChunkName: "reception" */ '../views/Supplier/Reception.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        }
    },
    {
        path: '/reception/:id',
        name: 'reception.view',
        component: () => import(/* webpackChunkName: "reception" */ '../views/Supplier/Reception.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        }
    },
]
