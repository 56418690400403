export default [
    {
        path: '/expenses-category',
        name: 'expenses-category.index',
        component: () => import(/* webpackChunkName: "expensesCategory" */ '../views/ExpensesCategory/ExpensesCategory.vue'),
        meta: {
			layout: "default",
            auth: true,
            roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive'],
        },
    },
    {
        path: '/add-expenses-category',
        name: 'expenses-category.store',
        component: () => import(/* webpackChunkName: "addExpensesCategory" */ '../views/ExpensesCategory/AddExpensesCategory.vue'),
        meta: {
			layout: "default",
            auth: true,
            roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive'],
        },
    },
    {
        path: '/expenses-category/:id/edit',
        name: 'expenses-category.edit',
        component: () => import(/* webpackChunkName: "editExpensesCategory" */ '../views/ExpensesCategory/EditExpensesCategory.vue'),
        meta: {
			layout: "default",
            auth: true,
            roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive'],
        },
    }
]
