export default [
	{
		path: '/subscriber',
		name: 'subscriber.index',
		component: () => import(/* webpackChunkName: "subscriber" */ '../views/Subscriber/Subscriber.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration'],
		},
	},
	{
		path: '/add-subscriber',
		name: 'subscriber.store',
		component: () => import(/* webpackChunkName: "addSubscriber" */ '../views/Subscriber/AddSubscriber.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration'],
		}
	},
	{
		path: '/subscriber/:id/edit',
		name: 'subscriber.edit',
		component: () => import(/* webpackChunkName: "editSubscriber" */ '../views/Subscriber/EditSubscriber.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration'],
		}
	}
]
