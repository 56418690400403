export default [
    {
        path: '/account',
        name: 'account.index',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account/Account.vue'),
        meta: {
			layout: "default",
            auth: true,
            roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'],
        },
    },
    {
        path: '/add-account',
        name: 'account.store',
        component: () => import(/* webpackChunkName: "addAccount" */ '../views/Account/AddAccount.vue'),
        meta: {
			layout: "default",
            auth: true,
            roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'],
        }
    },
    {
        path: '/account/:id/edit',
        name: 'account.edit',
        component: () => import(/* webpackChunkName: "editAccount" */ '../views/Account/EditAccount.vue'),
        meta: {
			layout: "default",
            auth: true,
            roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'],
        },
    },
	{
		path: '/account-log/:id/',
		name: 'account.log',
		component: () => import(/* webpackChunkName: "accountLog" */ '../views/Account/AccountLog.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'],
		},
	},
]
