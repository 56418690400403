export default [
	{
		path: '/tax-account',
		name: 'taxAccount.index',
		component: () => import(/* webpackChunkName: "taxAccount" */ '../views/TaxAccount/TaxAccount.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'],
		},
	},
	{
		path: '/tax-account/:id/edit',
		name: 'tax-account-edit',
		component: () => import(/* webpackChunkName: "editTaxAccount" */ '../views/TaxAccount/EditTaxAccount.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'accounting'],
		},
	},
]
