export default [
    {
        path: '/province',
        name: 'province.index',
        component: () => import(/* webpackChunkName: "province" */ '../views/Province/Province.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration'],
        },
    },
    {
        path: '/add-province',
        name: 'province.store',
        component: () => import(/* webpackChunkName: "addProvince" */ '../views/Province/AddProvince.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration'],
        }
    },
    {
        path: '/province/:id/edit',
        name: 'province.edit',
        component: () => import(/* webpackChunkName: "editProvince" */ '../views/Province/EditProvince.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration'],
        }
    }
]
