export default [
    {
        path: '/proposal',
        name: 'proposal.index',
        component: () => import(/* webpackChunkName: "proposal" */ '../views/Proposal/Proposal.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        },
    },
	{
		path: '/proposal/:id/view',
		name: 'proposal.view',
		component: () => import(/* webpackChunkName: "viewProposal" */ '../views/Proposal/ViewProposal.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/proposalPDF/:id',
		name: 'ProposalPDF',
		component: () => import(/* webpackChunkName: "proposalPDF" */ '../views/Proposal/ProposalPDF.vue'),
		meta: {
			auth: true,
			layout: "empty"
		}
	},
    {
        path: '/add-proposal',
        name: 'proposal.store',
        component: () => import(/* webpackChunkName: "addProposal" */ '../views/Proposal/AddProposal.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        },
    },
]
