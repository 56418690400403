<template>
    <div :class="className">
        <el-input
            ref="input"
            :placeholder="placeholder"
            :type="type"
            :modelValue="text"
            :autofocus="autofocus"
            :clearable="clearable"
            :disabled="disabled"
            :show-password="showPassword"
            :required="required"
            :maxlength="maxlength"
            :minlength="minlength"
            :rows="rows"
            :show-word-limit="showWordLimit"
            @input="inputListeners"
            @clear="clearInput"
        />
    </div>
</template>

<script>
    export default {
        name: 'AppInput',

        props: {
            className: {
                type: String,
                default: '',
            },
            placeholder: {
                type: String,
                default: '',
            },
            type: {
                type: String,
                default: 'text',
            },
            text: {
                type: [Number, String],
                default: '',
            },
            autofocus: {
                type: Boolean,
                default: false,
            },
            clearable: {
                type: Boolean,
                default: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            showPassword: {
                type: Boolean,
                default: false,
            },
            required: {
                type: Boolean,
                default: false,
            },
            maxlength: {
                type: Number,
                default: 255,
            },
            minlength: {
                type: Number,
                default: 1,
            },
            rows: {
                type: Number,
                default: 2,
            },
            showWordLimit: {
                type: Boolean,
                default: false,
            },
        },

        emits: ['update:text'],

        methods :{
            inputListeners(event) {
                this.$emit('update:text', event);
            },
            clearInput() {
                this.$emit('update:text', '');
            }
        }
    }
</script>
