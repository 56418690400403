export default [
	{
		path: '/service-rate',
		name: 'serviceRate.index',
		component: () => import(/* webpackChunkName: "taskType" */ '../views/ServiceRate/ServiceRate.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
]
