import { createStore } from 'vuex'
import state from './state.js';
import getters from './getters.js';
import mutations from './mutations.js';
import actions from './actions.js';

import authUser from './modules/authUser/index.js';
import configs from './modules/configs/index.js';
import current from './modules/current/index.js';

export default createStore({
	state: Object.assign({}, state),
	getters,
	mutations,
	actions,
	modules: {
		authUser,
		configs,
	  	current,
	}
})
