export default {
    id: '',
    name: '',
    firstName: '',
    lastName: '',
    noLogin: '',
    lastLogin: '',
    isActive: '',
    timezone_id: '',
    subscriber_id: '',
    isAdmin: '',
    created_at: '',
    site_id: '',
    profilePicture: '',
    role: {
        slug: '',
        type: ''
    },
	role_type: '',
	role_slug: '',
}
