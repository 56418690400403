export default [
    {
        path: '/country',
        name: 'country.index',
        component: () => import(/* webpackChunkName: "country" */ '../views/Country/Country.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration'],
        },
    },
    {
        path: '/add-country',
        name: 'country.store',
        component: () => import(/* webpackChunkName: "addCountry" */ '../views/Country/AddCountry.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration'],
        }
    },
    {
        path: '/country/:id/edit',
        name: 'country.edit',
        component: () => import(/* webpackChunkName: "editCountry" */ '../views/Country/EditCountry.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration'],
        }
    }
]
