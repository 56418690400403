export default [
	{
		path: '/ticket',
		name: 'ticket.index',
		component: () => import(/* webpackChunkName: "ticket" */ '../views/Service/Ticket.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/add-ticket',
		name: 'ticket.store',
		component: () => import(/* webpackChunkName: "addTicket" */ '../views/Service/AddTicket.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/ticket-status',
		name: 'ticketStatus.index',
		component: () => import(/* webpackChunkName: "ticketStatus" */ '../views/Service/TicketStatus.vue'),
		meta: {
			layout: "default",
			auth: true,
			roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive'],
		},
	},
	{
		path: '/ticket/:id/edit',
		name: 'ticket.edit',
		component: () => import(/* webpackChunkName: "editTicket" */ '../views/Service/EditTicket.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/ticket/:id/view',
		name: 'ticket.view',
		component: () => import(/* webpackChunkName: "viewTicket" */ '../views/Service/ViewTicket.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/task',
		name: 'task.index',
		component: () => import(/* webpackChunkName: "task" */ '../views/Service/Task.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		},
	},
	{
		path: '/my-tickets',
		name: 'MyTickets',
		component: () => import(/* webpackChunkName: "myTickets" */ '../views/Service/MyTickets.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['client'],
		}
	},
	{
		path: '/create-my-ticket',
		name: 'CreateMyTicket',
		component: () => import(/* webpackChunkName: "createMyTicket" */ '../views/Service/CreateMyTicket.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['client'],
		}
	},
	{
		path: '/edit-my-ticket/:id',
		name: 'EditMyTicket',
		component: () => import(/* webpackChunkName: "editMyTicket" */ '../views/Service/EditMyTicket.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['client'],
		}
	},
	{
		path: '/view-my-ticket/:id',
		name: 'ViewMyTicket',
		component: () => import(/* webpackChunkName: "viewMyTicket" */ '../views/Service/ViewMyTicket.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['client'],
		}
	},
]
