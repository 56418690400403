<template>
	<el-config-provider :locale="locale">
		<component :is="layout">
			<router-view />
		</component>
	</el-config-provider>
</template>

<script>
import { defineComponent, ref, computed, onMounted, onUnmounted, inject } from "vue";
import { useRouter } from "vue-router";
import { createI18n } from 'vue-i18n';
import { ElConfigProvider } from 'element-plus'
import fr from 'element-plus/es/locale/lang/fr'
import es from 'element-plus/es/locale/lang/es'
import en from 'element-plus/es/locale/lang/en'

export default defineComponent({
    name: 'App',

	components: {
		ElConfigProvider
	},

    setup() {
        const localeEmit = ref();
		const languages = { fr, es, en }
		const i18n = createI18n();
        const { currentRoute } = useRouter();
        const layout = computed(
            () => currentRoute.value.meta.layout ? `${currentRoute.value.meta.layout}-layout` : 'empty-layout'
        );
		const localeToUse = computed(() => {
            if (typeof localeEmit.value === 'undefined') {  // on refresh or starting session
                return languages[localStorage.getItem('currentLocale')];
            }

            return languages[localeEmit.value]
        })
        const emitter = inject('emitter');

        onMounted(() => {
            emitter.on('set-locale', (locale) => {   // *Listen* for event
                localeEmit.value = locale;
            });
        })

        onUnmounted(() => emitter.off('set-locale', () => {}))

        return {
            i18n,
            layout,
			locale: localeToUse
        };
    },

    async created() {
        await this.$store.dispatch('tryLogin');

        if (this.$store.getters.isAuthenticated) {
			// Get the state of the current user
			const user = this.$store.getters.getAuthUser;
			this.$store.getters.getConfigs;

			// Set the current selected subscriber id
			if (user && user.subscriber_id) {
				this.$store.commit('setCurrentSelectedSubscriberId', user.subscriber_id);
			}

			if (user && user.isAdmin) {
				this.$store.commit('setCurrentSelectedSubscriberId', localStorage.getItem('subscriber_id'));
			}
        }
    },

    mounted() {
        this.emitter.on('set-locale', locale => {
            // Set locale here
            this.$i18n.locale = locale;
        });
    },

    unmounted() {
        this.emitter.off('set-locale', () => {});
    }
});
</script>

<style lang="less">
#app {
    font-family: 'Open Sans', Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>
