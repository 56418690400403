import { createApp } from 'vue'
import App from './App.vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import router from './router'
import store from './store'

// Plugins
import { i18n } from './plugins/vue-i18n-next.js'
import mitt from 'mitt';
const emitter = mitt();
import wait from "@meforma/vue-wait-for";

// Styles
import './assets/tailwind.css'
import 'element-plus/dist/index.css'
import './assets/css/app.less'

import { bootElementUI } from '@/plugins/element.js';
import { bootEChart } from '@/plugins/echarts.js';
import { bootSwiper } from '@/plugins/swiper.js';

import DefaultLayout from './layouts/DefaultLayout.vue';
import EmptyLayout from './layouts/EmptyLayout.vue';
import AppButton from "./elements/AppButton";
import AppInput from "./elements/AppInput";
import AppLoading from './elements/AppLoading.vue';
import AppTooltip from './elements/AppTooltip.vue';
import Maska from 'maska';
import Vue3AutoCounter from 'vue3-autocounter';
import VueApexCharts from "vue3-apexcharts";

const app = createApp(App);
app.config.globalProperties.emitter = emitter;
app.component('default-layout', DefaultLayout);
app.component('empty-layout', EmptyLayout);
app.component(AppButton.name, AppButton);
app.component(AppInput.name, AppInput);
app.component(AppLoading.name, AppLoading);
app.component(AppTooltip.name, AppTooltip);
app.component('auto-counter', Vue3AutoCounter);
app.component('apex-chart', VueApexCharts);
bootElementUI(app);
bootEChart(app);
bootSwiper(app);

app.provide('emitter', emitter);
app.use(store).use(router).use(i18n, VueAxios, axios).use(Maska).use(wait).mount('#app')
