export default [
    {
        path: '/product-categorization',
        name: 'productCategorization.index',
        component: () => import(/* webpackChunkName: "productCategorization" */ '../views/Product/ProductCategorization.vue'),
        meta: {
			layout: "default",
            auth: true,
            roles: ['administrator', 'webmaster', 'representative', 'support', 'manager', 'executive', 'sale'],
        }
    },
    {
        path: '/product',
        props: route => ({ query: route.query.subscriber_id }),
        name: 'product.index',
        component: () => import(/* webpackChunkName: "product" */ '../views/Product/Product.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        }
    },
    {
        path: '/product-list',
        name: 'productList.index',
        component: () => import(/* webpackChunkName: "productList" */ '../views/Product/ProductList.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        }
    },
    {
        path: '/product-attribute',
        name: 'productAttribute.index',
        component: () => import(/* webpackChunkName: "productAttribute" */ '../views/Product/ProductAttribute.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration'],
        }
    },
    {
        path: '/add-product-attribute',
        name: 'productAttribute.store',
        component: () => import(/* webpackChunkName: "addProductAttribute" */ '../views/Product/AddProductAttribute.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration'],
        }
    },
    {
        path: '/product-attribute/:id/edit',
        name: 'productAttribute.edit',
        component: () => import(/* webpackChunkName: "editProductAttribute" */ '../views/Product/EditProductAttribute.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration'],
        }
    },
    {
        path: '/product-template',
        name: 'productTemplate.index',
        component: () => import(/* webpackChunkName: "productTemplate" */ '../views/Product/ProductTemplate.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        }
    },
    {
        path: '/client/product/:id',
        name: 'client.product',
        component: () => import(/* webpackChunkName: "clientProduct" */ '../views/Product/ClientProduct.vue'),
        meta: {
			layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber', 'client'],
        }
    },
	{
		path: '/serial-bank',
		name: 'serialBank.index',
		component: () => import(/* webpackChunkName: "serialBank" */ '../views/Product/SerialBank/SerialBank.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		}
	},
	{
		path: '/batch',
		name: 'batch.index',
		component: () => import(/* webpackChunkName: "batch" */ '../views/Product/Batch/Batch.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		}
	},
	{
		path: '/manufacturer',
		name: 'manufacturer.index',
		component: () => import(/* webpackChunkName: "manufacturer" */ '../views/Product/Manufacturer/Manufacturer.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		}
	},
	{
		path: '/attribute',
		name: 'attribute.index',
		component: () => import(/* webpackChunkName: "attribute" */ '../views/Product/Attribute/Attribute.vue'),
		meta: {
			layout: "default",
			auth: true,
			roleTypes: ['administration', 'subscriber'],
		}
	},
    {
        path: '/inventory-movement:subscriber_id?',
        name: 'inventory-movement.index',
        component: () => import(/* webpackChunkName: "inventory-movement" */ '../views/InventoryMovement/InventoryMovement.vue'),
        meta: {
            layout: "default",
            auth: true,
            roleTypes: ['administration', 'subscriber'],
        },
    }
];
